import styled from '@emotion/styled';
// import Lottie from 'lottie-react';
import { memo } from 'react';

import { ModalProps } from '@/components/common/CustomModal';
import GstakeModal from '@/components/enhanced/GstakeModal';
import useAlert from '@/hooks/useAlert';
import { ReactComponent as HeaderGrtSvg } from '@/static/img/gstake/header_grt.svg';
import { ReactComponent as HeaderLivepeerSvg } from '@/static/img/gstake/header_livepeer.svg';
import StreamerPng from '@/static/img/gstake/icon_streamer.png';

export const ProjectItems = [
  {
    key: 'graph',
    name: 'The Graph',
    available: true,
    icon: <HeaderGrtSvg />,
  },
  {
    key: 'livepeer',
    name: 'Livepeer',
    available: false,
    icon: <HeaderLivepeerSvg />,
  },
  {
    key: 'streamr',
    name: 'Streamr',
    available: false,
    icon: <img width="24px" height="24px" src={StreamerPng} />,
  },
];

const ProjectsModal = memo(({ visible, onCancel }: ModalProps) => {
  const { setAlert } = useAlert();
  return (
    <ProjectsModalWrap visible={visible} width={600} onCancel={onCancel}>
      <div className="inner">
        {ProjectItems.map((item) => (
          <SingleProject
            onClick={() => {
              if (item.available) {
                onCancel();
              } else {
                setAlert({ message: 'Coming Soon!', type: 'notice' });
                // onCancel();
              }
            }}
            key={item.key}
          >
            {item.icon}
            {item.name}
          </SingleProject>
        ))}
      </div>
    </ProjectsModalWrap>
  );
});

ProjectsModal.displayName = 'ProjectsModal';

export default ProjectsModal;

const ProjectsModalWrap = styled(GstakeModal)`
  width: 600px !important;
  border-radius: 16px !important;
  min-height: 324px;
  padding: 0 !important;
  border: 1px solid #414349 !important;
  background: #282830 !important;

  .inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 84px 0;
    gap: 50px;
  }
`;

const SingleProject = styled.div`
  display: flex;
  width: 400px;
  height: 48px;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #414349;
  background: #383841;
  color: #fff;
  font-family: 'Bai Jamjuree';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;
