import type {
  BusinessDay,
  ChartOptions,
  IChartApi,
  ISeriesApi,
  UTCTimestamp,
} from 'lightweight-charts';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? DeepPartial<U>[]
    : T[P] extends readonly (infer X)[]
    ? readonly DeepPartial<X>[]
    : DeepPartial<T[P]>;
};

type PriceNumber = number;
type TimeNumber = number;
export type MarketApiData = [TimeNumber, PriceNumber];

export type OnHoverFunction = ({
  time,
  price,
}: {
  time?: UTCTimestamp | BusinessDay | Date | string;
  price?: number | string;
}) => void;
export interface ChartViewProps {
  data: MarketApiData[];
  onHover: OnHoverFunction;
  height: number;
  isFetching: boolean;
}

export interface ChartViewAdapterProps extends ChartViewProps {
  lineColor: string;
  topColor: string;
  bottomColor: string;
}

interface IOnekeyChartApi extends IChartApi {
  // eslint-disable-next-line camelcase
  _onekey_series?: ISeriesApi<'Area'>;
}
export function createChartDom(
  createChartFunc: (
    container: HTMLElement,
    options?: DeepPartial<ChartOptions>,
  ) => IChartApi,
  domNode: HTMLElement,
  onHover: OnHoverFunction,
  height: number,
) {
  const chart = createChartFunc(domNode, {
    height,
    layout: {
      background: {
        color: 'transparent',
      },
      textColor: '#89899B',
    },
    crosshair: {
      vertLine: { visible: false },
      horzLine: { visible: false },
    },
    grid: {
      vertLines: { visible: false },
      horzLines: { visible: false, color: 'rgba(137, 137, 155, 0.4)' },
    },
    timeScale: {
      visible: true,
      fixLeftEdge: true,
      fixRightEdge: true,
      lockVisibleTimeRangeOnResize: true,
      borderColor: '#89899B',
    },
    overlayPriceScales: {
      borderVisible: false,
    },
    leftPriceScale: {
      visible: false,
    },
    rightPriceScale: {
      visible: true,
      borderVisible: true,
      borderColor: '#89899B',
    },
    handleScale: {
      pinch: false,
      mouseWheel: false,
    },
  });
  const handleResize = () => {
    chart.applyOptions({ width: domNode.clientWidth });
  };
  chart.subscribeCrosshairMove(({ time, seriesPrices }) => {
    onHover({ time, price: seriesPrices.values().next().value });
  });
  chart.timeScale().fitContent();
  window.addEventListener('resize', handleResize);
  // @ts-ignore
  window._onekey_chart = chart;
  return { chart, handleResize };
}

export function updateChartDom({
  lineColor,
  topColor,
  bottomColor,
  data,
}: {
  lineColor: string;
  topColor: string;
  bottomColor: string;
  data: MarketApiData[];
}) {
  const formattedData = (data as [UTCTimestamp, number][]).map(([time, value]) => ({
    time,
    value,
  }));
  // @ts-ignore
  const chart = window._onekey_chart as IOnekeyChartApi;
  if (!chart._onekey_series) {
    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor,
      bottomColor,
      lineWidth: 2,
      crosshairMarkerBorderColor: '#3a9670',
      crosshairMarkerRadius: 5,
    });
    newSeries.setData(formattedData);
    chart._onekey_series = newSeries;
    return;
  }
  const series = chart._onekey_series;
  series.applyOptions({ lineColor, topColor, bottomColor });
  series.setData(formattedData);

  if (data.length > 2) {
    chart
      .timeScale()
      // https://github.com/tradingview/lightweight-charts/issues/1015
      .setVisibleLogicalRange({ from: 0.4, to: data.length - 1.4 });
  }
}

export type PriceApiProps = {
  networkId: string;
  contract?: string;
  vs_currency?: string;
  days: string;
  points?: string;
};

// export const fetchChartData = async ({
//   networkId,
//   contract = 'main',
//   // eslint-disable-next-line camelcase
//   vs_currency = 'usd',
//   points,
//   days,
// }: PriceApiProps) => {
//   return [
//     [1710892255961, 62252.25156622091],
//     [1710893127708.8687, 62049.337269179865],
//     [1710893999456.7373, 62112.02470939842],
//     [1710894871204.606, 62617.47604734151],
//     [1710895742952.4749, 62783.11840596272],
//     [1710896614700.3435, 63176.486883239886],
//     [1710897486448.2122, 62450.12511894469],
//     [1710898358196.0808, 62164.80489830657],
//     [1710899229943.9495, 62071.02977710044],
//     [1710900101691.818, 62329.696497108045],
//     [1710900973439.6868, 62771.218211817075],
//     [1710901845187.5557, 62811.75559412488],
//     [1710902716935.4243, 62818.91131368771],
//     [1710903588683.293, 62754.97138691939],
//     [1710904460431.1616, 62855.9017367613],
//     [1710905332179.0303, 63263.11982973126],
//     [1710906203926.899, 63106.794893851096],
//     [1710907075674.7676, 62129.95147579103],
//     [1710907947422.6365, 62021.83634787864],
//     [1710908819170.5051, 61890.58927307055],
//     [1710909690918.3738, 61236.84253476989],
//     [1710910562666.2424, 61510.979781597394],
//     [1710911434414.111, 61249.793717030094],
//     [1710912621599, 60913.60130313711],
//     [1710914049657.7173, 61340.24084265115],
//     [1710914921405.586, 61610.13954360272],
//     [1710915793153.4546, 61776.76171898304],
//     [1710916664901.3232, 61832.46390512556],
//     [1710917536649.192, 61820.845606616334],
//     [1710918408397.0605, 61953.19474018801],
//     [1710919280144.9292, 62273.91559677206],
//     [1710920151892.798, 62742.813409012444],
//     [1710921023640.6667, 62784.70416318337],
//     [1710921895388.5354, 62892.316393332294],
//     [1710922767136.404, 62998.19358553944],
//     [1710923638884.2727, 63132.59289859155],
//     [1710924510632.1414, 62979.585119216994],
//     [1710925382380.01, 62776.713516991076],
//     [1710926254127.879, 62744.75732557612],
//     [1710927125875.7476, 63092.08936182003],
//     [1710927997623.6162, 63164.991124694505],
//     [1710928869371.4849, 63187.25426502192],
//     [1710929741119.3535, 63009.262681724416],
//     [1710930612867.2222, 63219.81480077633],
//     [1710931484615.0908, 62826.50048835483],
//     [1710932356362.9597, 62987.324470172476],
//     [1710933228110.8284, 63103.91088881154],
//     [1710934099858.697, 63582.39986161112],
//     [1710934971606.5657, 63668.067216437106],
//     [1710935843354.4343, 63276.07041025383],
//     [1710936715102.303, 63732.342125829455],
//     [1710937586850.1716, 64041.739349059826],
//     [1710938458598.0403, 63901.2368647236],
//     [1710939330345.9092, 63828.24110563317],
//     [1710940202093.7778, 63772.55844336318],
//     [1710941073841.6465, 63601.57625196213],
//     [1710941945589.5151, 63330.008454782896],
//     [1710942817337.3838, 63499.34586181389],
//     [1710943689085.2524, 63660.136580296814],
//     [1710944560833.121, 63950.37492735578],
//     [1710945432580.99, 64237.553270193246],
//     [1710946304328.8586, 64071.3967711213],
//     [1710947176076.7273, 64227.337233206155],
//     [1710948047824.596, 63964.184415398464],
//     [1710948919572.4646, 63683.28145420128],
//     [1710949791320.3333, 63555.5086801265],
//     [1710950663068.202, 63623.333707438964],
//     [1710951534816.0708, 63648.370145335044],
//     [1710952406563.9395, 62217.37248781235],
//     [1710953278311.808, 63105.70335057868],
//     [1710954150059.6768, 63068.90263962875],
//     [1710955021807.5454, 63549.89578608954],
//     [1710955893555.414, 64230.123083948034],
//     [1710956765303.2827, 64188.346076139875],
//     [1710957637051.1516, 64227.11094442743],
//     [1710958508799.0203, 64682.05177558967],
//     [1710959380546.889, 64359.599233290624],
//     [1710960252294.7576, 64260.2452040471],
//     [1710961124042.6262, 65332.82472097257],
//     [1710961995790.4949, 65597.49555144805],
//     [1710962867538.3635, 65337.911006303315],
//     [1710963739286.2324, 65552.17776494336],
//     [1710964611034.101, 65846.46178934806],
//     [1710965482781.9697, 66443.09657665345],
//     [1710966354529.8384, 66486.4611045918],
//     [1710967226277.707, 67723.70710701754],
//     [1710968098025.5757, 67083.25225340511],
//     [1710968969773.4443, 67022.0956671313],
//     [1710969841521.3132, 67376.47452767262],
//     [1710970713269.182, 67613.85930988453],
//     [1710971585017.0505, 67644.0194691901],
//     [1710972925942, 68033.5317131208],
//     [1710974200260.6565, 67578.9007538535],
//     [1710975072008.5251, 67381.95160435973],
//     [1710975943756.394, 67586.66667486173],
//     [1710976815504.2627, 67856.68991665918],
//     [1710977687252.1313, 67876.05918271892],
//     [1710978559000, 67751.44725187603],
//   ];
// };
