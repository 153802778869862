import BigNumber from 'bignumber.js';

export function formatMarketValueForFiexd(value?: number, fractionDigits?: number) {
  if (value) {
    // const resValue = fractionDigits
    //   ? value.toFixed(fractionDigits).replace(/0+$/g, '')
    //   : value.toFixed(2).replace(/0+$/g, '');

    return fractionDigits ? value.toFixed(fractionDigits) : value.toFixed(2);
  }
  return '0.00';
}

export function parseExponential(value?: number) {
  if (!value) return 0;
  const e = /\d(?:\.(\d*))?e([+-]\d+)/.exec(value.toExponential());
  return e ? value.toFixed(Math.max(0, (e[1] || '').length - parseInt(e[2]))) : value;
}

export function formatDecimalZero(value: number) {
  if (value >= 1) return formatMarketValueForFiexd(value);
  const noRexponentail = parseExponential(value);
  const effectIndex = noRexponentail.toString().search(/[^-.0]/g);
  const zeroCount = effectIndex - 2;
  const fiexdValue = formatMarketValueForFiexd(value, 3 + zeroCount);
  if (zeroCount >= 3) {
    return `0.{${zeroCount}}${fiexdValue.toString().substring(effectIndex)}`;
  }
  return fiexdValue;
}

export function calculateGains({
  basePrice,
  price,
}: {
  basePrice?: number;
  price?: number | string | null;
}) {
  let gainTextColor = 'text-subdued';
  let gainTextBg = 'surface-neutral-subdued';
  const priceNum = new BigNumber(typeof price === 'string' ? +price : price ?? 0);

  const gain = priceNum.minus(basePrice ?? 0);

  if (priceNum.isNaN() || gain.isNaN()) {
    return {
      gain: 0,
      gainNumber: 0,
      gainText: '0.00',
      percentageGain: '0.00%',
      isPositive: false,
      gainTextColor,
      gainTextBg,
    };
  }
  const gainNumber = gain.toNumber();
  const isPositive = gainNumber >= 0;
  let percentageGain: number | string = basePrice
    ? gain.dividedBy(basePrice).multipliedBy(100).toNumber()
    : 0;

  const _gainText = formatDecimalZero(gainNumber);
  const gainText = `${isPositive ? '+' : ''}${
    _gainText.includes('{') ? _gainText : (+_gainText).toFixed(4)
  }`;
  // const gainText = isPositive
  //   ? `+${formatDecimalZero(gainNumber)}`
  //   : formatDecimalZero(gainNumber);

  percentageGain = isPositive
    ? `+${percentageGain.toFixed(2)}%`
    : `${percentageGain.toFixed(2)}%`;

  if (!isPositive) {
    gainTextColor = '#f44336';
    gainTextBg = 'surface-critical-default';
  } else {
    gainTextColor = '#119316';
    gainTextBg = 'surface-success-default';
  }

  return {
    gain,
    gainNumber,
    gainText,
    gainTextColor,
    gainTextBg,
    percentageGain,
    isPositive,
  };
}

export function getSuggestedDecimals(price: number) {
  return price < 1
    ? Math.min(8, price.toString().slice(2).slice().search(/[^0]/g) + 3)
    : 2;
}

export function formatAmount(
  value?: BigNumber.Value,
  precision = 4,
  roundingMode: BigNumber.RoundingMode = 1,
) {
  if (!value) {
    return '';
  }
  const bn = new BigNumber(value);
  if (bn.isNaN()) {
    return '';
  }
  return bn.decimalPlaces(precision, roundingMode).toFixed();
}
