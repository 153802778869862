import './utils/polyfills';
import './styles/index.css';
import '@rainbow-me/rainbowkit/styles.css';
import * as Sentry from '@sentry/react';

import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { WagmiProvider, http } from 'wagmi';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';

import { WalletProvider } from '@/web3/WalletProvider';

import { IntlProvider } from './components/hoc/IntlProvider';
import Router from './routers/index';
import store from './store';
import GlobalStyles from './styles/GlobalStyles';
import { DEFAULT_WAGMI_CHAIN, isMainnet } from './web3/constants';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './components/hoc/ErrorBoundary';

const container = document.getElementById('root')!;
const root = createRoot(container);

console.log(DEFAULT_WAGMI_CHAIN, 'check DEFAULT_WAGMI_CHAIN');

export const config = getDefaultConfig({
  appName: 'Gstake',
  projectId: 'dff009c9243a40f6aa128608782d9624',
  chains: [DEFAULT_WAGMI_CHAIN],
  transports: {
    [DEFAULT_WAGMI_CHAIN.id]: http(),
  },
});

// const { chains, publicClient } = configureChains([arbitrumSepolia], [publicProvider()]);

// const { connectors } = getDefaultWallets({
//   appName: 'Gstake Project',
//   projectId: 'dff009c9243a40f6aa128608782d9624',
//   chains,
// });

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://13d42e5617e8a86ee30fcc61c49f6474@o4506807201103872.ingest.sentry.io/4506807208247296',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV === 'production' && isMainnet,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
});

// enableInExpoDevelopment: true,
// debug: false,
// tracesSampleRate: isProduction ? 1 : 0.2,
// environment: isProduction ? 'production' : 'test'

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <WagmiProvider config={config}>
            <RainbowKitProvider>
              <WalletProvider>
                <IntlProvider>
                  <GlobalStyles />
                  <Router />
                </IntlProvider>
              </WalletProvider>
            </RainbowKitProvider>
          </WagmiProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
