import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Lockr from 'lockr';
// import Lottie from 'lottie-react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalProps } from '@/components/common/CustomModal';
import ButtonLoading from '@/components/enhanced/ButtonLoading';
import GstakeModal from '@/components/enhanced/GstakeModal';
import TextCountDown from '@/components/enhanced/TextCountDonw';
import { useFaucetContract } from '@/hooks/queries/useFaucetContract';
import useAlert from '@/hooks/useAlert';
import { ReactComponent as EthSvg } from '@/static/img/gstake/faucet_eth.svg';
import { ReactComponent as GrtSvg } from '@/static/img/gstake/icon_grt.svg';
import { selectPendingEndResult, updatePendingEndResult } from '@/store/globalConfig';
import { CHAINS } from '@/web3/constants';
import { useActiveWeb3React } from '@/web3/WalletProvider';

const FaucetModal = memo(({ visible, onCancel }: ModalProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState('');
  const { faucetEth, faucetGrt, getLastEthAccess, getLastGrtAccess, faucetContract } =
    useFaucetContract();
  const { setAlert } = useAlert();
  const [lastFaucetGrtTime, setLastGrtAccessTime] = useState<number>();
  const [lastFaucetEthTime, setLastEthAccessTime] = useState<number>();
  const [faucetGrtAvailable, setFaucetGrtAvailable] = useState<boolean>();
  const [faucetEthAvailable, setFaucetEthAvailable] = useState<boolean>();
  const pendingEndResult = useSelector(selectPendingEndResult);

  // const lastFaucetInfo: Record<string, any> = Lockr.get('lastFaucetInfo') || {};

  useEffect(() => {
    if (!faucetContract) {
      return;
    }
    (async () => {
      const ethResult = await getLastEthAccess();
      if (ethResult) {
        const _lastFaucetEthTime = +ethResult * 1000;
        setLastEthAccessTime(
          24 * 60 * 60 - Math.abs(dayjs(_lastFaucetEthTime).diff(dayjs(), 'seconds')),
        );
        setFaucetEthAvailable(
          Math.abs(dayjs(_lastFaucetEthTime).diff(dayjs(), 'hours')) >= 24,
        );
      } else {
        setFaucetEthAvailable(true);
      }
      const grtResult = await getLastGrtAccess();
      if (grtResult) {
        const _lastFaucetGrtTime = +grtResult * 1000;
        setLastGrtAccessTime(
          24 * 60 * 60 - Math.abs(dayjs(_lastFaucetGrtTime).diff(dayjs(), 'seconds')),
        );
        setFaucetGrtAvailable(
          Math.abs(dayjs(_lastFaucetGrtTime).diff(dayjs(), 'hours')) >= 24,
        );
      } else {
        setFaucetGrtAvailable(true);
      }
    })();
  }, [faucetContract, pendingEndResult]);

  // const faucetGrtLeftTime = useMemo(() => {
  //   if (!lastFaucetGrtTime) {
  //     return 0;
  //   }
  //   return;
  // }, [faucetGrtAvailable, lastFaucetGrtTime]);

  // const faucetEthLeftTime = useMemo(() => {
  //   return 24 * 60 * 60 - Math.abs(dayjs(lastFaucetEthTime).diff(dayjs(), 'seconds'));
  // }, [lastFaucetEthTime, faucetEthAvailable]);

  const hanldeFaucet = useCallback(
    async (type: 'grt' | 'eth') => {
      try {
        setLoading(type);

        const result = type === 'grt' ? await faucetGrt() : await faucetEth();

        if (!result.hash) {
          setAlert({ message: 'Faucet failed, please try again later', type: 'error' });
        }

        await result.wait();

        dispatch(updatePendingEndResult(Math.random()));

        setAlert({
          message: 'Faucet success, please checkout your wallet balance',
          type: 'success',
        });
      } catch (error: any) {
        setAlert({
          message: error.message || 'Faucet failed, please try again later',
          type: 'error',
        });
      } finally {
        setLoading('');
      }
    },
    [faucetGrt, faucetEth],
  );

  return (
    <FaucetModalWrap visible={visible} width={460} onCancel={onCancel}>
      <div className="inner">
        <SingleFaucetContent>
          <div className="faucet-info-line">
            <div className="coin">
              <GrtSvg />
              GRT
            </div>

            <div className="stat">
              <div className="label">Request Amount</div>
              <div className="value">10000 GRT</div>
            </div>

            <div className="stat">
              <div className="label">Faucet Cooldown</div>
              <div className="value">~1 day</div>
            </div>
          </div>
          {faucetGrtAvailable ? (
            <FaucetConfirmButton onClick={() => hanldeFaucet('grt')}>
              {loading === 'grt' ? <ButtonLoading size={20} /> : 'Get GRT'}
            </FaucetConfirmButton>
          ) : (
            <FaucetConfirmButton className="disable">
              Available In&nbsp;
              {/* @ts-ignore: Unreachable code error */}
              <TextCountDown leftTime={lastFaucetGrtTime * 1000} />
            </FaucetConfirmButton>
          )}
        </SingleFaucetContent>

        <SingleFaucetContent>
          <div className="faucet-info-line">
            <div className="coin">
              <EthSvg />
              ETH
            </div>

            <div className="stat">
              <div className="label">Request Amount</div>
              <div className="value">0.01 ETH</div>
            </div>

            <div className="stat">
              <div className="label">Faucet Cooldown</div>
              <div className="value">~1 day</div>
            </div>
          </div>

          {faucetEthAvailable ? (
            <FaucetConfirmButton className="eth" onClick={() => hanldeFaucet('eth')}>
              {loading === 'eth' ? <ButtonLoading size={20} /> : 'Get ETH'}
            </FaucetConfirmButton>
          ) : (
            <FaucetConfirmButton className="disable eth">
              Available In&nbsp;
              {/* @ts-ignore: Unreachable code error */}
              <TextCountDown leftTime={lastFaucetEthTime * 1000} />
            </FaucetConfirmButton>
          )}
        </SingleFaucetContent>

        <SingleFaucetContent>
          <div className="learn-title">All the testnet tokens have been claimed?</div>
          <FaucetConfirmButton
            onClick={() =>
              window.open(
                'https://medium.com/@gstake/how-to-get-gstake-testnet-tokens-302ff6dbc8c4',
              )
            }
            className="learn"
          >
            Learn how to get Gstake testnet tokens
          </FaucetConfirmButton>
        </SingleFaucetContent>
      </div>
    </FaucetModalWrap>
  );
});

FaucetModal.displayName = 'FaucetModal';

export default FaucetModal;

const FaucetModalWrap = styled(GstakeModal)`
  width: 460px !important;
  border-radius: 16px !important;
  min-height: 324px;
  padding: 80px 16px 24px;
  background: #28282f !important;

  .inner {
  }
`;

const SingleFaucetContent = styled.div`
  width: 100%;
  margin-bottom: 16px;
  background: #1c1c25;
  border-radius: 24px;
  padding: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  .learn-title {
    font-family: Alibaba PuHuiTi 2;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    margin-bottom: 16px;
  }

  .faucet-info-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-bottom: 24px;

    > .coin {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      text-align: center;
      font-family: Alibaba PuHuiTi 2;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      > svg {
        margin-right: 6px;
      }
    }

    > .stat {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .label {
        color: #999;
        text-align: center;
        font-family: Alibaba PuHuiTi 2;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .value {
        color: #fff;
        text-align: center;
        font-family: Alibaba PuHuiTi 2;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;

const FaucetConfirmButton = styled.div`
  width: 380px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #6747ed;
  color: #fff;
  text-align: center;
  font-family: Alibaba PuHuiTi 2;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  &.eth {
    background: #28a9c4;
  }

  &.learn {
    background: #775005;
  }

  &.disable {
    cursor: not-allowed;
    background: rgba(103, 71, 237, 0.5);
    opacity: 1;

    &.eth {
      background: rgba(40, 169, 196, 0.5);
    }
  }
`;
