import react, { useCallback, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { useIntl } from 'react-intl';

import ChartView from './ChartView';
// import PriceLabel from './PriceLabel';

import type { MarketApiData, OnHoverFunction } from './chartService';
import dayjs from 'dayjs';
import ButtonLoading from '../ButtonLoading';
import { calculateGains } from '@/utils/priceUtils';
import styled from '@emotion/styled';
import { IntervalTypes } from '@/hooks/queries/useGstakeGraph';
import LoadingMask from '@/components/common/LodaingMask';

type ChartWithLabelProps = {
  data: MarketApiData[] | null;
  timeDefaultLabel: string;
  children: ReactNode;
  isFetching: boolean;
  onPriceSubscribe?: (price: number) => void;
  WstGRTprice: string;
  interval: IntervalTypes;
};

const ChartWithLabel: FC<ChartWithLabelProps> = ({
  data,
  isFetching,
  children,
  WstGRTprice,
  interval,
}) => {
  // const { formatDate } = useFormatDate();
  const intl = useIntl();

  const [price, setPrice] = useState<string | number | undefined>();
  const [time, setTime] = useState('');
  const basePrice = data?.length ? data[0][1] : 0;
  const latestPrice = data?.length ? data[data.length - 1][1] : 0;
  const currentPrice = useMemo(() => {
    if (!data) {
      return null;
    }
    if (price === 'undefined' || price === undefined) {
      return latestPrice;
    }
    if (typeof price === 'string') {
      return +price;
    }
    return price;
  }, [data, latestPrice, price]);

  const onHover = useCallback<OnHoverFunction>((hoverData) => {
    // The first data of each hover is an empty string, which needs to be filtered
    if (hoverData.price === '' && hoverData.time === '') {
      return;
    }
    let displayTime;
    if (hoverData.time instanceof Date) {
      displayTime = dayjs(hoverData.time).format('YYYY-MM-DD HH:mm:ss');
    } else if (typeof hoverData.time === 'number') {
      displayTime = dayjs(new Date(hoverData.time)).format('YYYY-MM-DD HH:mm:ss');
    } else if (typeof hoverData.time === 'string') {
      displayTime = dayjs(new Date(+hoverData.time)).format('YYYY-MM-DD HH:mm:ss');
    } else {
      displayTime = '';
    }
    setTime(displayTime);
    setPrice(hoverData.price);
  }, []);

  const priceLabel = useMemo(() => {
    if (!currentPrice || !basePrice) {
      return <div></div>;
    }

    const { gainText, percentageGain, gainTextColor } = calculateGains({
      basePrice,
      price: currentPrice,
    });

    return (
      <PriceLabel>
        <div className="current-price">${currentPrice.toFixed(4)}</div>
        <div className="gain-percent" style={{ color: gainTextColor }}>
          {/* {gainText} */}
          {percentageGain}
        </div>
        <div className="interval">{interval.toUpperCase()}</div>
      </PriceLabel>
    );
  }, [currentPrice, basePrice]);

  const emptyView = useMemo(() => {
    if (isFetching) {
      return <ButtonLoading />;
    }
    return <LoadingMask size={200} />;
  }, [intl, isFetching]);

  const chartView =
    data && data.length > 0 ? (
      <ChartView isFetching={isFetching} height={300} data={data} onHover={onHover} />
    ) : (
      emptyView
    );

  const chartViewWithSpinner = isFetching ? <ButtonLoading /> : chartView;
  return (
    <ChartWrapper>
      <div>
        <ChartHeader>
          <div className="left">
            <div className="title-symbol">
              The Graph Token <span>(GRT)</span>
            </div>
            {priceLabel}
          </div>

          <WstPrice>
            <div className="inner">1 WstGRT = {WstGRTprice} GRT</div>
          </WstPrice>
        </ChartHeader>
        <div>{data ? children : null}</div>
      </div>
      <div>{chartViewWithSpinner}</div>
    </ChartWrapper>
  );
};
ChartWithLabel.displayName = 'ChartWithLabel';
export default ChartWithLabel;

const ChartWrapper = styled.div`
  background: #282830;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .title-symbol {
    font-family: Alibaba PuHuiTi 2;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: #fff;
    display: flex;
    align-items: flex-end;
    justify-cotent: flex-start;

    span {
      font-family: Alibaba PuHuiTi 2;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: right;
      color: #fff;
      opacity: 0.4;
      margin-bottom: 3px;
      margin-left: 6px;
    }
  }
`;

const PriceLabel = styled.div`
  margin-left: 18px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .current-price {
    color: #fff;
    font-family: Alibaba PuHuiTi 2;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: right;
  }
  .gain-percent {
    font-family: Alibaba PuHuiTi 2;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: right;
    margin-left: 6px;
  }
  .interval {
    font-family: Alibaba PuHuiTi 2;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: rgba(255, 255, 255, 0.4);
    margin-left: 4px;
  }
`;

const WstPrice = styled.div`
  width: 190px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(259.59deg, #2b75aa 0%, #27b9b1 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  > .inner {
    height: 28px;
    width: 180px;
    background: #282830;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Alibaba PuHuiTi 2;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    text-align: left;
    color: #fff;
    padding: 0 10px;
    border-radius: 4px;
  }
`;
