import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import React, { useEffect, useMemo, useState } from 'react';

import Paginator from '@/components/common/InfinitePaginator';
import LoadingMask from '@/components/common/LodaingMask';
import HistoryTable, { RenderProps } from '@/components/enhanced/HistoryTable';
import { getHistoryInfo } from '@/hooks/queries/useGstakeGraph';
import { StyledPanel } from '@/pages/Main/components/MainPanel';
import { ReactComponent as WsGrtSvg } from '@/static/img/gstake/icon_wsgrt.svg';
import { useActiveWeb3React } from '@/web3/WalletProvider';

// const localizedFormat = require('dayjs/plugin/localizedFormat');
// dayjs.extend(localizedFormat);

const DEFAULT_SIZE = 20;

const StatusMap = ['', 'Transfer In', 'Transfer Out', 'Deposit', 'Withdraw'];

const ColorMap: Record<string, string> = {
  'Transfer In': '#51AD4D',
  'Transfer Out': '#D7353C',
  Deposit: '#51AD4D',
  Withdraw: '#D7353C',
};

const RewardHistory: React.FC = () => {
  const { account, isActive } = useActiveWeb3React();
  const [loading, setLoading] = useState(false);
  const [curPage, setCurPage] = useState(0);
  const [dataList, setDataList] = useState([]);

  const handleGetData = async (page: number) => {
    setLoading(true);
    try {
      const data = await getHistoryInfo(account as string, page, DEFAULT_SIZE);

      if (data?.wstGRTHistory?.length) {
        setDataList(data.wstGRTHistory);
        setCurPage(page);
      }
      setHasNext(data.wstGRTHistory.length === DEFAULT_SIZE);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetData(curPage);
  }, []);

  const [hasNext, setHasNext] = useState(true);

  const handlePageChange = (page: number) => {
    handleGetData(page);
  };

  const columns: RenderProps[] = [
    {
      title: 'Date',
      key: 'blockTimestamp',
      width: 150,
      render: (blockTimestamp) => (
        <>{dayjs(blockTimestamp * 1000).format('MM/DD/YYYY HH:mm:ss')}</>
      ),
      align: 'left',
    },
    {
      title: 'Type',
      key: 'type',
      width: 100,
      align: 'left',
      render: (type) => {
        const typeText = StatusMap[type];

        return <div style={{ color: ColorMap[typeText] }}>{StatusMap[type]}</div>;
      },
    },
    {
      title: (
        <TableCell>
          <WsGrtSvg /> Change
        </TableCell>
      ),
      key: 'wstGRT',
      render: (wstGRT) => {
        return (
          <TableCell>
            <WsGrtSvg />
            {new Decimal(wstGRT).toFixed(2, Decimal.ROUND_DOWN)}
          </TableCell>
        );
      },
      width: 100,
      align: 'left',
    },
    // {
    //   title: 'Apr',
    //   key: 'transHash',
    //   render: (transHash) => {
    //     return <>3.1%</>;
    //   },
    //   width: 100,
    //   align: 'left',
    // },
    {
      title: (
        <TableCell>
          <WsGrtSvg /> Balance
        </TableCell>
      ),
      key: 'balance',
      render: (balance) => {
        return (
          <TableCell>
            <WsGrtSvg /> {new Decimal(balance).toFixed(2, Decimal.ROUND_DOWN)}
          </TableCell>
        );
      },
      width: 100,
      align: 'left',
    },
  ];

  const [clicked, setClicked] = useState(0);

  return (
    <DashboardWrapper height={735}>
      <div className="inner">
        <div onClick={() => setClicked((v) => v + 1)} className="title">
          Wallet history{clicked > 20 && ` env: ${import.meta.env.VITE_ENV}`}
        </div>
        <RewardHistoryWrapper style={{ color: '#fff' }}>
          {loading && <LoadingMask size={200} />}

          <HistoryTable
            emptyTxt={'EMPTY'}
            renderkey={columns}
            listData={isActive ? dataList : []}
            rowStyle={{
              padding: '24px 0 24px 0',
              margin: '0',
            }}
            headerStyle={{
              height: '64px',
              alignItems: 'center',
              borderTop: '1px solid rgba(196, 196, 196, 0.1)',
              borderBottom: '1px solid rgba(196, 196, 196, 0.1)',
            }}
            // renderMore={true}
            // onMoreClick={handleNext}
            // hasMore={hasMore}
            // loading={!isSuccess}
          />
          {isActive && dataList.length > 0 && (
            <Paginator
              current={curPage}
              hasNext={hasNext}
              onChange={handlePageChange}
              loading={!!loading}
            />
          )}
        </RewardHistoryWrapper>
      </div>
    </DashboardWrapper>
  );
};

export default RewardHistory;

const DashboardWrapper = styled(StyledPanel)`
  width: 800px;
  padding: 24px;
  padding-top: 36px;

  .inner {
    .title {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 0;
      margin-bottom: 24px;
    }
  }
`;

const TableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const RewardHistoryWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const TimeSort = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    margin-left: 4px;
  }

  &.reverse {
    > svg {
      transform: rotate(180deg);
    }
  }
`;

const FilterBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const MenuWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 24px;

  span {
    font-family: 'Alibaba';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 18px;
  }
`;

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .status-dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;

    &.processing {
      background: #e4d121;
    }
    &.completed {
      background: #0ebd7e;
    }
    &.failed {
      background: #b02727;
    }
  }

  span {
    font-family: 'Alibaba';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #c2c7ce;
    margin-left: 8px;
  }
`;

const HashWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .clickable-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 8px;

    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

const TooltipNodeWrap = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
