import { createSlice } from '@reduxjs/toolkit';

// import {CHAINID_MAP} from 'web3/wallet/chains'
import { UserData } from '@/hooks/queries/useAuthApis';

import { IStoreState } from '.';

export type ClaimingToken = {
  amountOfGRT: string;
  id: number;
  status: string;
  wstGRT: string;
  undelegateInfo: {
    tokensLockedUntil: number;
  };
};

export const { actions, reducer } = createSlice({
  name: 'user',
  initialState: {
    clkAdded: {} as Record<number, boolean>,
    userInfo: JSON.parse(localStorage.getItem('stored_userInfo') || '{}'),
    tokenClaimed: [] as number[],
    claimableItemAmount: [] as ClaimingToken[],
  },
  reducers: {
    updateClkAdded: (
      state,
      { payload }: { payload: { chainId: number; status: boolean } },
    ) => {
      state.clkAdded[payload.chainId] = payload.status;
    },
    updateUserInfo: (state, { payload }) => {
      const userInfo = { ...state.userInfo, ...payload };
      localStorage.setItem('stored_userInfo', JSON.stringify(userInfo));
      state.userInfo = userInfo;
    },
    updateTokenClaimed: (state, { payload }) => {
      state.tokenClaimed = Array.from(new Set([...state.tokenClaimed, ...payload]));
    },
    updateClaimableItemAmount: (state, { payload }) => {
      state.claimableItemAmount = Array.from(
        new Set([...state.claimableItemAmount, payload]),
      );
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchBanners.rejected, errorReducer)
  //     .addCase(fetchBanners.pending, pendingReducer)
  //     .addCase(fetchBanners.fulfilled, (state, { payload }) => {
  //       state.banners = payload
  //       state.loading = 'idle'
  //     })
  // },
});

export const updateClkAdded = actions.updateClkAdded;
export const selectClkAdded = (state: IStoreState) => state.user.clkAdded;

export const updateUserInfo = actions.updateUserInfo;
export const selectUserInfo = (state: IStoreState): UserData | null =>
  state.user.userInfo;

export const updateTokenClaimed = actions.updateTokenClaimed;
export const selectTokenClaimed = (state: IStoreState) => state.user.tokenClaimed;

export const updateClaimableItemAmount = actions.updateClaimableItemAmount;
export const selectClaimableItemAmount = (state: IStoreState) =>
  state.user.claimableItemAmount;
