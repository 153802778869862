import styled from '@emotion/styled';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TipsText from '@/components/common/Tooltip';
import { WalletIconPng } from '@/components/enhanced/ConnectButton';
import TextLoading from '@/components/enhanced/TextLoading';
import { GstakeStatsInfo, GstakeUserInfo } from '@/hooks/queries/useGstakeGraph';
import useAddToken from '@/hooks/useAddToken';
import { GstakeTokenList } from '@/hooks/useCjcNftAddr';
import { ReactComponent as AprSvg } from '@/static/img/gstake/apr_icon.svg';
import { ReactComponent as AddSvg } from '@/static/img/gstake/icon-add.svg';
import { ReactComponent as InfoSvg } from '@/static/img/gstake/info_svg.svg';
import { updateAccountModalVisible } from '@/store/globalConfig';
import { getPrecisionedAmount } from '@/utils/format';
import { getApr } from '@/utils/graphUtils';
import { formatAddress } from '@/web3/format';
import { useActiveWeb3React } from '@/web3/WalletProvider';
import useAlert from '@/hooks/useAlert';

const StatusCard = memo(
  ({
    userInfo,
    gstakeStats,
    grtBalance,
    wstGRTBalance,
  }: {
    userInfo?: GstakeUserInfo;
    gstakeStats?: GstakeStatsInfo;
    grtBalance: string;
    wstGRTBalance: string;
  }) => {
    const [aprLoading, setAprLoading] = useState(false);
    const dispatch = useDispatch();
    const [curWallet, setCurWallet] = useState('');
    const { addToken } = useAddToken();
    const { setAlert } = useAlert();

    const [aprData, setAprData] = useState('-');

    const { isActive, account } = useActiveWeb3React();

    useEffect(() => {
      if (isActive) {
        const walletType = localStorage.getItem('wagmi.wallet') || '';
        setCurWallet(walletType.replaceAll('"', ''));
      }
    }, [isActive]);

    useEffect(() => {
      (async () => {
        try {
          setAprLoading(true);
          const apr = await getApr();
          setAprData(apr);
        } catch (error) {
          console.log(error);
        } finally {
          setAprLoading(false);
        }
      })();
    }, []);

    const showAccountModal = () => {
      dispatch(updateAccountModalVisible(true));
    };

    const [addingToken, setAddingToken] = useState(false);

    const handleAddToken = () => {
      if (addingToken) {
        setAlert({ type: 'warning', message: 'Please wait for the token to be added.' });
        return;
      }

      setAddingToken(true);
      addToken(
        {
          address: GstakeTokenList.wstGRT.tokenAddress,
          symbol: 'wstGRT',
          decimals: 18,
        },
        () => {
          setAddingToken(false);
        },
      );
    };

    return (
      <CardWrapper>
        <div className="line1">
          <AprBlock>
            <div className="content">
              <div className="label">
                ESTIMATED APR{' '}
                <TipsText
                  overlayText={`Note: This APR is based on today's data, extrapolated over a year.`}
                  placement="topLeft"
                  triggerNode={
                    <div style={{ marginLeft: 5 }}>
                      <InfoSvg />
                    </div>
                  }
                ></TipsText>
              </div>
              <div className="value">
                <AprSvg />{' '}
                {aprLoading ? (
                  <TextLoading
                    width={60}
                    height={30}
                    color="#00fff0"
                    justifyContent="center"
                  />
                ) : (
                  <div
                    style={{
                      width: 60,
                      height: 30,
                      textAlign: 'right',
                      flexShrink: 0,
                      paddingRight: 8,
                      lineHeight: '30px',
                    }}
                  >
                    {aprData}
                  </div>
                )}{' '}
                %
              </div>
            </div>
          </AprBlock>

          <WalletInfoWrap onClick={showAccountModal}>
            <div className="wallet-addr">{formatAddress(account)}</div>

            {WalletIconPng?.[curWallet]}
          </WalletInfoWrap>
        </div>

        <div className="line2">
          <StakeAvailable>
            <div className="label">
              Available to stake <div className="circle"></div>
            </div>
            <div className="value">{getPrecisionedAmount(grtBalance, 'GRT')} GRT</div>
          </StakeAvailable>

          <StakedAmount>
            <div className="label">Staked amount</div>
            <div className="value">
              {wstGRTBalance ? getPrecisionedAmount(wstGRTBalance, 'wstGRT') : '0.00'}{' '}
              WstGRT{' '}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <AddTokenButton onClick={handleAddToken} className="add-button">
                <AddSvg />
              </AddTokenButton>
            </div>
          </StakedAmount>
        </div>
      </CardWrapper>
    );
  },
);

StatusCard.displayName = 'StatusCard';

const AddTokenButton = styled.div`
  margin-left: 4px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 8px;
  border: 1px solid #59899d;
  background: linear-gradient(260deg, #184769 0%, #2f5351 100%);
  padding: 20px;
  margin-bottom: 24px;

  .line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }

  .line2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AprBlock = styled.div`
  width: 125px;
  height: 38px;
  position: relative;
  border: solid 2px #847fa0;
  .content {
    width: 117px;
    height: 46px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: linear-gradient(260deg, #345054 0%, #304d5a 100%);
    z-index: 1;
    padding: 0 4px;

    .label {
      color: #fff;
      text-align: center;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
    }

    .value {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00fff0;
      font-family: 'Alibaba';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      > svg {
        margin-right: 6px;
        flex-shrink: 0;
      }

      &.withdraw {
        color: #fff;
        font-family: 'Alibaba';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  &.withdraw {
    width: 88px;

    .content {
      width: 80px;

      .label {
        width: 80px;
      }

      .value {
        justify-content: space-between;
      }
    }
  }
`;

export const WalletInfoWrap = styled.div`
  width: 130px;
  height: 30px;
  border-radius: 64px;
  background: #212a4c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 16px;
  border-radius: 42px;
  margin-left: 8px;
  cursor: pointer;

  > img {
    width: 24px !important;
    height: 24px !important;
  }

  .wallet-addr {
    color: #c8c8c9;
    font-family: 'Alibaba';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 8px;
  }

  .cjc-value {
    font-family: 'Alibaba';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #dedede;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .dropdown-arrow {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    transition: all 0.5s;

    &.reverse {
      transform: rotate(180deg);
    }
  }
`;

export const StakeAvailable = styled.div`
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-family: 'Alibaba';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;

    .circle {
      margin-left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #1fcfaf;
    }
  }
  .value {
    color: #fff;
    font-family: 'Alibaba';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const StakedAmount = styled.div`
  border-radius: 8px;
  border: 1px solid #617f3a;
  background: #09323f;
  width: 200px;
  height: 82px;
  flex-shrink: 0;
  padding: 16px;
  .label {
    color: #fff;
    font-family: 'Alibaba';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 13px;
  }
  .value {
    color: #fff;
    font-family: 'Alibaba';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .add-button {
      margin-left: 4px;
      cursor: pointer;
    }
  }
`;

export default StatusCard;
