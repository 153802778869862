import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';

import MainPanel from './components/MainPanel';
import Questions from './components/Questions';

const Gstake: React.FC = () => {
  useEffect(() => {
    document.title = 'Stake with Gstake';
  }, []);

  return (
    <GstakeWrapper>
      <TitleWrap>
        <div className="title">Stake GRT</div>
        <div className="sub-title">Stake GRT and receive WstGRT while staking.</div>
      </TitleWrap>

      <MainPanel />

      <Questions />
    </GstakeWrapper>
  );
};

export default Gstake;

const GstakeWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
`;

const TitleWrap = styled.div`
  ${tw`flex flex-col justify-center items-center`}
  margin-bottom: 48px;

  .title {
    color: #fff;
    font-family: 'Alibaba';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-title {
    color: #cacbcd;
    font-family: 'Alibaba';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
