import styled from '@emotion/styled';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from 'bignumber.js';
import clsx from 'clsx';
import Decimal from 'decimal.js';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '@/components/common/Button';
import CustomInput from '@/components/common/Input';
import TipsText from '@/components/common/Tooltip';
import ApprovalOperator from '@/components/enhanced/ApprovalOperator';
import ButtonLoading from '@/components/enhanced/ButtonLoading';
import LoginButton, {
  ConnectButtonPositionType,
} from '@/components/enhanced/ConnectButton';
import { useGstakeContract } from '@/hooks/queries/useGstakeContract';
import { useGstakeStats, useGstakeUserInfo } from '@/hooks/queries/useGstakeGraph';
import useAlert from '@/hooks/useAlert';
import { GSTAKE_CONTRACT, GstakeTokenList } from '@/hooks/useCjcNftAddr';
import { ReactComponent as GrtSvg } from '@/static/img/gstake/icon_grt.svg';
import { ReactComponent as InfoSvg } from '@/static/img/gstake/info_svg.svg';
import { selectPendingEndResult, updatePendingEndResult } from '@/store/globalConfig';
import { INPUT_REG } from '@/utils';
import { getPrecisionedAmount } from '@/utils/format';
import { useActiveWeb3React } from '@/web3/WalletProvider';

import StatusCard from './StatusCard';
import TransactionModal, {
  TransactionProps,
  TransactionStatus,
} from './TransactionModal';
import { erc20Abi } from 'viem';
import useBalance from '@/hooks/useBalance';
import { getParsedEthersError } from '@enzoferey/ethers-error-parser';
import { getErrorText } from '@/utils/getErrorText';

const MainPanel = memo(() => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const [inputErrored, setInputErrored] = useState('');
  const [maxed, setMaxed] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [transModalProps, setTransModalProps] = useState<TransactionProps>({});
  const { isActive, account, web3Provider } = useActiveWeb3React();
  const pendingEndKey = useSelector(selectPendingEndResult);

  const { grtValue, wstgrtValue } = useBalance();

  const { deposit } = useGstakeContract();

  const { data: userInfo } = useGstakeUserInfo(account as string, pendingEndKey, {
    enabled: isActive,
  });

  const { data: gstakeStats } = useGstakeStats(pendingEndKey);
  const getAvailValue = useCallback((val: string) => {
    const availVal = `${val}`.match(INPUT_REG(2))?.[0];
    if (!availVal) {
      setValue('');
      return;
    }

    setValue(availVal);
  }, []);

  const handleChange = (e: any) => {
    setMaxed(false);
    getAvailValue(e.target.value);
  };

  const handleMax = (e: any) => {
    setMaxed(true);
    getAvailValue(grtValue + '');
    return;
  };

  const finalReceiveValue = useMemo(() => {
    if (!(+value > 0)) {
      return 0;
    }
    if (!gstakeStats?.exchangeRate || !gstakeStats?.taxRate) {
      return value;
    }

    const wstVal = new Decimal(value).times(gstakeStats.exchangeRate);
    const feeVal = wstVal.times(gstakeStats.taxRate);
    return wstVal.minus(feeVal).toFixed(2, Decimal.ROUND_DOWN);
  }, [value, gstakeStats]);

  useEffect(() => {
    if (+value > +grtValue) {
      setInputErrored(`GRT amount must not be greater than ${grtValue}`);
    } else {
      setInputErrored('');
    }
  }, [value, grtValue]);

  const handleStake = async (needApprove?: boolean) => {
    if (!web3Provider) {
      return;
    }

    if (gstakeStats?.depositLeft && new BigNumber(gstakeStats?.depositLeft).lt(value)) {
      setInputErrored(
        `GRT amount must not be greater than ${new Decimal(
          gstakeStats?.depositLeft,
        ).toFixed(2, Decimal.ROUND_DOWN)}`,
      );
      return;
    }

    setTransModalProps({
      status: TransactionStatus.WAITING_CONFIRM,
      content: `You are now staking ${value} GRT`,
      subContent: (
        <>
          Staking {value} GRT.
          <br />
          You will receive {finalReceiveValue} WstGRT
        </>
      ),
      footer: 'Confirm this transaction in your wallet',
    });

    try {
      setLoading(true);

      const realValue = maxed ? grtValue.toString() : value;
      const amount = parseUnits(realValue, 18);

      const result = await deposit(amount, needApprove);

      if (!result.hash) {
        setTransModalProps({});
      }

      setTransModalProps({
        status: TransactionStatus.PENDING,
        content: `You are now staking ${value} GRT`,
        subContent: 'Awaiting block confirmation',
        hash: result.hash,
      });

      await result.wait();

      dispatch(updatePendingEndResult(Math.random()));

      setTimeout(() => {
        dispatch(updatePendingEndResult(Math.random()));
      }, 4000);

      setTransModalProps({
        status: TransactionStatus.COMPLETE,
        content: `Your new balance is ${finalReceiveValue} WstGRT`,
        subContent: 'Staking operation was successful.',
        hash: result.hash,
      });
    } catch (error: any) {
      console.log('deposit err:', getParsedEthersError(error));
      setAlert({ message: getErrorText(error), type: 'error' });
      setLoading(false);
      setTransModalProps({});
    } finally {
      setLoading(false);
      setValue('');
    }
  };

  const rederPercentage = (val: any) => {
    if (!val || isNaN(+val)) {
      return '--';
    }
    return +new Decimal(val).times(100).valueOf();
  };

  return (
    <StyledPanel height={isActive ? 640 : 436}>
      {isActive && (
        <StatusCard
          grtBalance={grtValue}
          wstGRTBalance={wstgrtValue}
          userInfo={userInfo}
          gstakeStats={gstakeStats}
        />
      )}

      <InputWrapper errored={Boolean(inputErrored)}>
        <div className="symbol-switcher">
          <GrtSvg />
        </div>
        <StyledInput
          overflowText={maxed}
          disabled={!isActive}
          placeholder={'GRT amount'}
          value={value}
          onChange={handleChange}
        />
        <MaxButton maxed={maxed} disabled={!isActive} onClick={handleMax}>
          MAX
        </MaxButton>

        {!!inputErrored && <ErrorLabel>{inputErrored}</ErrorLabel>}
      </InputWrapper>

      {!isActive ? (
        <LoginButton type={ConnectButtonPositionType.CONTENT} showWalletInfo={false} />
      ) : (
        <ApprovalOperator
          targetAddress={GstakeTokenList.GRT.tokenAddress}
          operatorAddress={GSTAKE_CONTRACT}
          tokenType="ERC20"
          loading={loading}
          isDisabled={!(+value > 0) || !!inputErrored}
          funcWithApprove={() => {
            handleStake(true);
          }}
          funcWithApproveText="Submit"
          customClass={clsx(!(+value > 0) && 'disabled', 'burning-nft')}
          approvedComponent={
            <ConfirmButton
              disabled={!(+value > 0) || !!inputErrored}
              onClick={() => handleStake()}
              loading={loading}
              loadingElement={<ButtonLoading />}
            >
              {loading ? '' : 'Submit'}
            </ConfirmButton>
          }
          minApproveAmount={Number(value)}
          allowText={<>Check wallet for a signature request.</>}
        />
      )}

      <InfoWrapper>
        <div className="single-line">
          <div className="label">You will receive</div>
          <div className="value">{finalReceiveValue} WstGRT</div>
        </div>
        <div className="single-line">
          <div className="label">Exchange rate</div>
          <div className="value">
            1 GRT ={' '}
            {gstakeStats?.exchangeRate
              ? getPrecisionedAmount(gstakeStats?.exchangeRate, 'wstGRT')
              : '-'}{' '}
            WstGRT
          </div>
        </div>
        <div className="single-line">
          <div className="label">
            Stake tax{' '}
            <TipsText
              overlayText={`Please note that the 0.5% staking tax is collected by The Graph protocol, This means if you are staking 1000 GRT, you will automatically burn 5 GRT.`}
              placement="topLeft"
              triggerNode={
                <div style={{ marginLeft: 5 }}>
                  <InfoSvg />
                </div>
              }
            ></TipsText>
          </div>
          <div className="value">{rederPercentage(gstakeStats?.taxRate)}%</div>
        </div>
        <div className="single-line">
          <div className="label">
            Reward fee{' '}
            <TipsText
              overlayText={`Please note: this fee applies to staking rewards only, and is NOT taken from your staked amount.`}
              placement="topLeft"
              triggerNode={
                <div style={{ marginLeft: 5 }}>
                  <InfoSvg />
                </div>
              }
            ></TipsText>
          </div>
          <div className="value">{rederPercentage(gstakeStats?.feeRate)}%</div>
        </div>
      </InfoWrapper>

      {Object.keys(transModalProps).length > 0 && (
        <TransactionModal
          onCancel={() => setTransModalProps({})}
          visible={true}
          {...transModalProps}
        />
      )}
    </StyledPanel>
  );
});

MainPanel.displayName = 'MainPanel';

export const StyledPanel = styled.div<{ height?: number }>`
  width: 500px;
  height: auto;
  border-radius: 16px;
  padding: 40px;
  background: #282830;
  border-radius: 16px;
  gap: 24px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1px; /* control the border thickness */
    background: linear-gradient(146deg, #549372 0%, #2e6cb8 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
`;

export const InputWrapper = styled.div<{ errored: boolean }>`
  width: 100%;
  height: 64px;
  background: #0c0d0f;
  border-radius: 8px;
  border: ${(props) => (props.errored ? 'solid 1px red' : 'solid 1px#2F5656')};
  padding: 12px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${(props) => (props.errored ? '50px' : '24px')};

  &.withdraw-input {
    border-radius: 8px;
    border: 1px solid #2f5656;
    background: #0d191a;
  }

  .symbol-switcher {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 8px;
    padding-right: 8px;
    cursor: not-allowed;
  }
`;

export const StyledInput = styled(CustomInput)`
  width: 100%;
  flex-shrink: 1;
  input {
    font-style: normal;
    font-weight: 600;
    font-size: 20.5644px;
    line-height: 24px;
    color: #fff;
    background: none;
  }
`;

export const MaxButton = styled.div<{ disabled?: boolean; maxed: boolean }>`
  width: 80px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: #6ec0c6;
  font-family: 'Alibaba';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.8;
  border-radius: 8px;
  background: #1f5d61;

  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;

  &:hover {
    /* background: ${(props) =>
      props.disabled ? '#49505a' : 'rgba(207, 207, 207, 0.4)'}; */
    color: ${(props) => (props.disabled ? 'rgba(254, 254, 254, 0.8)' : '#c2b8ec')};
    opacity: 1;
  }

  &.withdraw-max {
    color: #6ec0c6;
    background: #1f5d61;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 188px;
  margin-top: 24px;

  > .single-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }

    .label {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

export const ConfirmButton = styled(CustomButton)`
  width: 100%;
  height: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 8px;
  background: linear-gradient(90deg, #388a76 0%, #005f53 100%);
  color: #fff;
  text-align: center;
  font-family: 'Alibaba';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  opacity: 0.9;
  margin-right: 0;

  &:hover {
    opacity: 1;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
  }

  &.approve-button {
    height: 38px;
    font-family: 'Alibaba';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
  }

  &.claim-button {
    border-radius: 8px;
    background: linear-gradient(90deg, #388a76 0%, #005f53 100%);
  }

  &.close {
    border-radius: 8px;
    background: #2f3138;
  }
`;

export const AlertTips = styled.div`
  width: 420px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #ce4e4e;
  background: #b52323;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: 'Alibaba';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ErrorLabel = styled.div`
  padding: 0 16px;
  height: 25px;
  border-radius: 4px;
  background: #b52323;
  color: #fff;
  font-family: 'Alibaba';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -30px;
  left: 0;
`;

export default MainPanel;
