import styled from '@emotion/styled';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from 'bignumber.js';
import clsx from 'clsx';
import Decimal from 'decimal.js';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import TipsText from '@/components/common/Tooltip';
import ApprovalOperator from '@/components/enhanced/ApprovalOperator';
import ButtonLoading from '@/components/enhanced/ButtonLoading';
import LoginButton, {
  ConnectButtonPositionType,
} from '@/components/enhanced/ConnectButton';
import { useGstakeContract } from '@/hooks/queries/useGstakeContract';
import { useGstakeStats, useGstakeUserInfo } from '@/hooks/queries/useGstakeGraph';
import useAlert from '@/hooks/useAlert';
import { GSTAKE_CONTRACT, GstakeTokenList } from '@/hooks/useCjcNftAddr';
import {
  ConfirmButton,
  ErrorLabel,
  InfoWrapper,
  InputWrapper,
  MaxButton,
  StyledInput,
  StyledPanel,
} from '@/pages/Main/components/MainPanel';
import TransactionModal, {
  OperationType,
  TransactionProps,
  TransactionStatus,
} from '@/pages/Main/components/TransactionModal';
import { ReactComponent as GrtSvg } from '@/static/img/gstake/icon_grt.svg';
import { ReactComponent as WsGrtSvg } from '@/static/img/gstake/icon_wsgrt.svg';
import { ReactComponent as InfoSvg } from '@/static/img/gstake/info_svg.svg';
import { ReactComponent as RateSvg } from '@/static/img/gstake/rate_icon.svg';
import { ReactComponent as TimeSvg } from '@/static/img/gstake/time_icon.svg';
import { selectPendingEndResult, updatePendingEndResult } from '@/store/globalConfig';
import { updateClaimableItemAmount } from '@/store/user';
import { INPUT_REG } from '@/utils';
import { getPrecisionedAmount } from '@/utils/format';
import { useActiveWeb3React } from '@/web3/WalletProvider';

import WithdrawStatusCard from './WithdrawStatusCard';
import useBalance from '@/hooks/useBalance';
import { getErrorText } from '@/utils/getErrorText';

const WithdrawPanel = memo((props: any) => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const [inputErrored, setInputErrored] = useState('');
  const navigate = useNavigate();
  const [maxed, setMaxed] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [grtApprovedAmount, setGrtApprovedAmount] = useState<boolean | number>(0);
  const [transModalProps, setTransModalProps] = useState<TransactionProps>({});
  const { isActive, account, web3Provider } = useActiveWeb3React();
  const pendingEndKey = useSelector(selectPendingEndResult);
  const { wstgrtValue } = useBalance();

  const { withdraw, getClaimTokenIdFromLogs } = useGstakeContract();

  const { data: userInfo, isFetching } = useGstakeUserInfo(
    account as string,
    pendingEndKey,
    {
      enabled: isActive,
      keepPreviousData: true,
    },
  );

  const { data: gstakeStats } = useGstakeStats(pendingEndKey);

  const withdrawTime = useMemo(() => {
    if (!gstakeStats) {
      return 28;
    }
    const { exchangeRate, pendingGRT, matchTime, undelegateFreezeTime } = gstakeStats;

    const availGRT = new BigNumber(value || wstgrtValue).div(exchangeRate);
    if (availGRT.lt(pendingGRT)) {
      const _time = Math.round(+(matchTime || 86400) / 60 / 60);
      return _time + (_time === 1 ? ' hour' : ' hours');
    } else {
      const _time = Math.round(+(undelegateFreezeTime || 2419200) / 60 / 60 / 24);
      return _time + (_time === 1 ? ' day' : ' days');
    }
  }, [value, wstgrtValue, gstakeStats]);

  const getAvailValue = useCallback((val: string) => {
    const availVal = `${val}`.match(INPUT_REG(2))?.[0];
    if (!availVal) {
      setValue('');
      return;
    }

    setValue(availVal);
  }, []);

  const handleChange = (e: any) => {
    setMaxed(false);
    getAvailValue(e.target.value);
  };

  const availMaxValue = useMemo(() => {
    if (!gstakeStats?.withdrawLeft) {
      return wstgrtValue;
    }

    const maxAvailValue = new BigNumber(wstgrtValue).lt(gstakeStats?.withdrawLeft)
      ? wstgrtValue
      : gstakeStats?.withdrawLeft;

    return maxAvailValue;
  }, [gstakeStats, wstgrtValue]);

  const handleMax = (e: any) => {
    setMaxed(true);

    getAvailValue(availMaxValue);
  };

  const finalReceiveValue = useMemo(() => {
    if (!(+value > 0)) {
      return 0;
    }
    if (!gstakeStats?.exchangeRate) {
      return value;
    }

    return new Decimal(value)
      .times(1 / +gstakeStats.exchangeRate)
      .toFixed(2, Decimal.ROUND_DOWN);
  }, [value, gstakeStats]);

  useEffect(() => {
    dispatch(updatePendingEndResult(Math.random()));
  }, []);

  useEffect(() => {
    if (new BigNumber(value).gt(wstgrtValue)) {
      setInputErrored(
        `WstGRT amount must not be greater than ${getPrecisionedAmount(
          wstgrtValue,
          'WstGRT',
        )}`,
      );
    } else if (new BigNumber(value).gt(gstakeStats?.withdrawLeft ?? '0')) {
      setInputErrored(`WstGRT amount must not be greater withdrawal limit`);
    } else if (
      new BigNumber(value).gt(0) &&
      new BigNumber(value).lt(gstakeStats?.withdrawMinLimit ?? '0.01')
    ) {
      setInputErrored(
        `WstGRT amount must be greater than ${gstakeStats?.withdrawMinLimit ?? '0.01'}`,
      );
    } else {
      setInputErrored('');
    }
  }, [value, wstgrtValue]);

  const handleWithdraw = async () => {
    if (!web3Provider) {
      return;
    }

    setTransModalProps({
      status: TransactionStatus.WAITING_CONFIRM,
      content: `You are requesting withdrawal for ${value} WstGRT`,
      subContent: `Requesting withdrawal for ${value} WstGRT`,
      footer: 'Confirm this transaction in your wallet',
    });

    try {
      const realValue = maxed ? availMaxValue.toString() : value;

      const amount = parseUnits(realValue, 18);

      const result = await withdraw(amount);

      if (!result.hash) {
        setTransModalProps({});
      }

      setTransModalProps({
        status: TransactionStatus.PENDING,
        content: `You are requesting withdrawal for ${value} WstGRT`,
        subContent: `Awaiting block confirmation`,
        hash: result.hash,
      });

      const finalResult = await result.wait();

      console.log(finalResult, 'check final');

      if (finalResult.logs) {
        const tokenGetted = getClaimTokenIdFromLogs(finalResult.logs);
        console.log(tokenGetted, 'check log 2');
        if (tokenGetted) {
          dispatch(updateClaimableItemAmount(tokenGetted));
        }
      }

      // dispatch(updatePendingEndResult(Math.random()));

      // setTimeout(() => {
      //   dispatch(updatePendingEndResult(Math.random()));
      // }, 4000);

      setTransModalProps({
        type: OperationType.WITHDRAW,
        status: TransactionStatus.COMPLETE,
        withdrawWstGrt: Number(value),
        content: 'Withdrawal request successfully sent',
        subContent: 'Transaction success',
        hash: result.hash,
      });
    } catch (error: any) {
      setAlert({ message: getErrorText(error), type: 'error' });
      setTransModalProps({});
      setValue('');
    } finally {
      setValue('');
    }
  };

  return (
    <StyledPanel>
      {isActive && (
        <WithdrawStatusCard
          wstBalance={wstgrtValue}
          userInfo={userInfo}
          gstakeStats={gstakeStats}
        />
      )}

      <InputWrapper className="withdraw-input" errored={Boolean(inputErrored)}>
        <div className="symbol-switcher">
          <WsGrtSvg />
        </div>
        <StyledInput
          overflowText={maxed}
          disabled={!isActive}
          placeholder={'WstGRT amount'}
          value={value}
          onChange={handleChange}
        />
        <MaxButton
          className="withdraw-max"
          maxed={maxed}
          disabled={!isActive}
          onClick={handleMax}
        >
          MAX
        </MaxButton>

        {!!inputErrored && <ErrorLabel>{inputErrored}</ErrorLabel>}
      </InputWrapper>

      {!isActive && (
        <ExchangeRateInfo>
          <div className="single-info">
            <div className="label">Rate</div>
            <div className="value">
              1 WstGRT ={' '}
              {gstakeStats?.exchangeRate
                ? new Decimal(1)
                    .div(gstakeStats?.exchangeRate)
                    .toFixed(2, Decimal.ROUND_DOWN)
                : '-'}{' '}
              GRT
            </div>
          </div>
          <div className="single-info">
            <div className="label">Withdraws waiting time</div>
            <div className="value">1-2 day(s)</div>
          </div>
        </ExchangeRateInfo>
      )}

      <GrtAmountInfo className={clsx(isActive && 'show-rate')}>
        {isActive && (
          <div className="exchange-line">
            <div className="single-info">
              <div className="label">
                <RateSvg />
                Rate
              </div>
              <div className="value">
                1 WstGRT ={' '}
                {gstakeStats?.exchangeRate
                  ? new Decimal(1)
                      .div(gstakeStats?.exchangeRate)
                      .toFixed(2, Decimal.ROUND_DOWN)
                  : '-'}{' '}
                GRT
              </div>
            </div>
            <div className="single-info">
              <div className="label">
                <TimeSvg />
                Withdraws waiting time
              </div>
              <div className="value">{withdrawTime}</div>
            </div>
          </div>
        )}
        <div className="left">
          <GrtSvg /> GRT
        </div>
        <div className="right">{finalReceiveValue} GRT</div>
      </GrtAmountInfo>

      {!isActive ? (
        <LoginButton type={ConnectButtonPositionType.CONTENT} showWalletInfo={false} />
      ) : (
        <ApprovalOperator
          targetAddress={GstakeTokenList.wstGRT.tokenAddress}
          operatorAddress={GSTAKE_CONTRACT}
          tokenType="ERC20"
          loading={loading}
          isDisabled={!(+value > 0) || !!inputErrored}
          funcWithApprove={() => handleWithdraw()}
          funcWithApproveText="Submit"
          customClass={clsx(!(+value > 0) && 'disabled', 'burning-nft')}
          approvedComponent={
            <ConfirmButton
              disabled={!(+value > 0) || !!inputErrored}
              onClick={() => handleWithdraw()}
              loading={loading}
              loadingElement={<ButtonLoading />}
            >
              {loading ? '' : 'Submit'}
            </ConfirmButton>
          }
          afterApproved={(v) => setGrtApprovedAmount(v)}
          minApproveAmount={300}
          allowText={<>Check wallet for a signature request.</>}
        />
      )}

      <InfoWrapper style={{ height: 80 }}>
        <div className="single-line">
          <div className="label">
            Withdrawal limit{' '}
            <TipsText
              overlayText={`Wthdrawal limit is refreshed daily. For more information, please refer to the withdrawal mechanism.`}
              placement="topLeft"
              triggerNode={
                <div style={{ marginLeft: 5 }}>
                  <InfoSvg />
                </div>
              }
            ></TipsText>
          </div>
          <div className="value">
            {getPrecisionedAmount(gstakeStats?.withdrawLeft || '0', 'wstGRT')} WstGRT
          </div>
        </div>

        <div className="single-line">
          <div className="label">Allowance</div>
          <div className="value">{grtApprovedAmount} WstGRT</div>
        </div>
        <div className="single-line">
          <div className="label">Exchange rate</div>
          <div className="value">
            1 WstGRT ={' '}
            {gstakeStats?.exchangeRate
              ? new Decimal(1)
                  .div(gstakeStats?.exchangeRate)
                  .toFixed(2, Decimal.ROUND_DOWN)
              : '-'}{' '}
            GRT
          </div>
        </div>
      </InfoWrapper>

      {Object.keys(transModalProps).length > 0 && (
        <TransactionModal
          onCancel={() => {
            if (transModalProps.status === TransactionStatus.COMPLETE) {
              navigate('/withdrawal/claim');
              return;
            }
            setTransModalProps({});
          }}
          visible={true}
          {...transModalProps}
        />
      )}
    </StyledPanel>
  );
});

WithdrawPanel.displayName = 'WithdrawPanel';

export default WithdrawPanel;

const ExchangeRateInfo = styled.div`
  width: 420px;
  height: 64px;
  border-radius: 8px;
  background: #0a151f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;

  .single-info {
    width: 140px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .label {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.4;
    }
    .value {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 4px;
    }
  }
`;

const GrtAmountInfo = styled.div`
  width: 420px;
  height: 88px;
  border-radius: 8px;
  background: #1b1b1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: 'Alibaba';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 24px;
  margin-bottom: 24px;
  position: relative;

  &.show-rate {
    height: 158px;
    padding-top: 72px;
  }

  .exchange-line {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 12px;
    width: 396px;
    height: 60px;
    border-radius: 8px;
    background: #274150;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;

    .label {
      color: #31b9a9;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > svg {
        margin-right: 8px;
      }
    }

    .value {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 28px;
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > svg {
      margin-right: 8px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
