import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const DOT_COLOR = '#999';

interface TextLoadingProps {
  justifyContent?: string;
  width?: number;
  height?: number;
  color?: string;
}

const TextLoading: React.FC<TextLoadingProps> = ({
  width = 100,
  height = 30,
  justifyContent = 'flex-start',
  color,
}) => {
  return (
    <TextLoadingWrapper
      color={color}
      width={width}
      height={height}
      justifyContent={justifyContent}
    >
      <div className="text-loading"></div>
    </TextLoadingWrapper>
  );
};

export default TextLoading;

export const waves = (color: string) => keyframes`
            0% {
                box-shadow: -15px -10px 0 0 ${color}, 0 -10px 0 0 ${color},
                    15px -10px 0 0 ${color};
            }
            15% {
                box-shadow: -15px -10px 0 0 ${color}, 0 -10px 0 0 ${color},
                    15px -10px 0 0 ${color};
            }
            30% {
                box-shadow: -15px -15px 0 0 ${color}, 0 -10px 0 0 ${color},
                    15px -10px 0 0 ${color};
            }
            45% {
                box-shadow:  -15px -10px 0 0 ${color}, 0 -15px 0 0 ${color},
                    15px -10px 0 0 ${color};
            }
            60% {
                box-shadow:-15px -10px 0 0 ${color}, 0 -10px 0 0 ${color},
                    15px -15px 0 0 ${color};
            }
            75% {
                box-shadow:  -15px -10px 0 0 ${color}, 0 -10px 0 0 ${color},
                    15px -10px 0 0 ${color};
            }
            100% {
                box-shadow: -15px -10px 0 0 ${color}, 0 -10px 0 0 ${color},
                    15px -10px 0 0 ${color};
            }
`;

const TextLoadingWrapper = styled.div<TextLoadingProps>`
  .text-loading {
    width: ${(props) => props.width + 'px'};
    height: ${(props) => props.height + 'px'};
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent};
    color: #fff;
    flex-shrink: 0;
    margin-left: ${(props) => (props.justifyContent === 'center' ? 0 : '15px')};

    &:before {
      /* -webkit-animation: mexican-wave 0.75s infinite ease backwards; */
      /* animation: mexicanwave 0.75s infinite ease backwards; */
      animation: ${(props) => waves(props.color || DOT_COLOR)} 1s infinite ease backwards;
      border-radius: 100%;
      content: '';
      height: 6px;
      position: absolute;
      /* -webkit-transform: translateY(6px); */
      transform: translateY(9px);
      width: 6px;
    }
  }
`;
