import { ItemType } from '@opensea/seaport-js/lib/constants';

import { isSameAddress } from '@/utils/format';
import { DEFAULT_CHAIN_ID } from '@/web3/constants';

export { DEFAULT_CHAIN_ID };
const chainId = DEFAULT_CHAIN_ID;

const GRAPH_MAP: Record<number, string> = {
  // 11155111: 'https://api.studio.thegraph.com/query/57666/gstake/version/latest',
  421614:
    'https://api.studio.thegraph.com/query/57666/gstake-arbitrum-sepolia/version/latest',
  42161: 'https://api.studio.thegraph.com/query/57666/gstake-thegraph/version/latest',
};

export const GRAPH_URL = GRAPH_MAP[DEFAULT_CHAIN_ID];

const CJC_ALL_CONTRCT_ADDR: Record<number, Record<string, string>> = {
  421614: {
    grt: '0xf8c05dCF59E8B28BFD5eed176C562bEbcfc7Ac04',
    wstgrt: '0x9253792d323Ef3E4BFd7481d8A010b054793B660',
    gstake: '0x9253792d323Ef3E4BFd7481d8A010b054793B660',
    grtSalt: '0xe33842a7acd1d5a1d28f25a931703e5605152dc48d64dc4716efdae1f5659591',
    faucet: '0x72cf118Ed942a64Ac4227F06B5E56B880346d9Be',

    cjc: '0x854e13f0a08e651873ace06abd27003b56ad2fb8',
    usdt: '0x5B99dCb3FaEBe7Ba0d1DA231Fd683C6301E29cBE',

    boxRouter: '0xc0FE491a5B74d1F008daAaC312c6743D6C8fA626',
    box: '0x412Dc140affe262C956b7AB14A69A35Fef6029e4',
    jockey: '0x6E916A3C51Eba15e625e5A08b936Ce77bfE44653',
    horse: '0x45Aecc4A323BfF43387ce2aAFCBeBb8271227D23',
    item: '0x27f723697E5a7De707B16A055cAf93859242421b',

    HorseSoul: '0x120FB89Ec90A7387Caf10efB858B7987548037DE',
    JockeySoul: '0x82A87B6119C42D057c9442b5EF64e4e510c84BDB',
    ItemSoul: '0x7ebe7500481859cea6a6c850e767e606f9811fd8',

    AlloyX: '0x2C97f7C91b28E962ae39f1B4a1580b6a58707e4C',
    MummifiedBody: '0x9780A8a48d1291fDB6449E60876BbCF565cE94d3',
    AlchemyStove: '0xc872bdb773e471f8a311be5c3dd57dbb1ca5e462',
    SoulStaker: '0x3D8b2Fb5F7F5C1854b335bF418154053Ed022878',
    Reborn: '0x136682FE6ffA7c7A9221E653E7A39b3BBd596E1c',

    seaport: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
    SeaSwapper: '0xB17bb9aeF964577d1eE374cA500b4D54Bcd88d21',
  },
  42161: {
    grt: '0x9623063377AD1B27544C965cCd7342f7EA7e88C7',
    wstgrt: '0x3ccb5569a36d864933fbc6881715d1afda1849d9',
    gstake: '0x3ccb5569a36d864933fbc6881715d1afda1849d9',
    grtSalt: '0xe33842a7acd1d5a1d28f25a931703e5605152dc48d64dc4716efdae1f5659591',

    cjc: '0x854e13f0a08e651873ace06abd27003b56ad2fb8',
    usdt: '0x5B99dCb3FaEBe7Ba0d1DA231Fd683C6301E29cBE',

    boxRouter: '0xc0FE491a5B74d1F008daAaC312c6743D6C8fA626',
    box: '0x412Dc140affe262C956b7AB14A69A35Fef6029e4',
    jockey: '0x6E916A3C51Eba15e625e5A08b936Ce77bfE44653',
    horse: '0x45Aecc4A323BfF43387ce2aAFCBeBb8271227D23',
    item: '0x27f723697E5a7De707B16A055cAf93859242421b',

    HorseSoul: '0x120FB89Ec90A7387Caf10efB858B7987548037DE',
    JockeySoul: '0x82A87B6119C42D057c9442b5EF64e4e510c84BDB',
    ItemSoul: '0x7ebe7500481859cea6a6c850e767e606f9811fd8',

    AlloyX: '0x2C97f7C91b28E962ae39f1B4a1580b6a58707e4C',
    MummifiedBody: '0x9780A8a48d1291fDB6449E60876BbCF565cE94d3',
    AlchemyStove: '0xc872bdb773e471f8a311be5c3dd57dbb1ca5e462',
    SoulStaker: '0x3D8b2Fb5F7F5C1854b335bF418154053Ed022878',
    Reborn: '0x136682FE6ffA7c7A9221E653E7A39b3BBd596E1c',

    seaport: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
    SeaSwapper: '0xB17bb9aeF964577d1eE374cA500b4D54Bcd88d21',
  },
  11155111: {
    grt: '0xCA59cCeb39bE1808d7aA607153f4A5062daF3a83',
    wstgrt: '0x19A3669e2E34e37cc6B87b37e98454D8bb290486',
    gstake: '0x19A3669e2E34e37cc6B87b37e98454D8bb290486',
    grtSalt: '0x51f3d585afe6dfeb2af01bba0889a36c1db03beec88c6a4d0c53817069026afa',
    faucet: '0x72cf118Ed942a64Ac4227F06B5E56B880346d9Be', // not available

    cjc: '0xc2F27e779421aAE9e48088BeD5284665154480e2',
    usdt: '0x4d1AfD2A0544B92aD456Acd5875F9b87A066563d',
    boxRouter: '0x5a0712324a447a4ba8c234960f7b97b60097ab64',
    box: '0x010b8FABc719d57080925A38E7d56a90d9ca2Eae',
    jockey: '0xc312523EE979197F7F339397d1Ecce9cD1993624',
    horse: '0x73Cd7a512042936FA657022553DCa58F8177559f',
    item: '0x6f60D3FF4c304B3329AE8A42860084246D707765',

    HorseSoul: '0x5B66d1784547ab4968c41E49bA298d6cD54AEB14',
    JockeySoul: '0x0D210668Ae530b3Db2890a17f512C7b4dE1cb150',
    ItemSoul: '0x02E4F2Ea0b540D0C4bb5a9D96892f442E1983D3E',

    AlloyX: '0x394dCacf202B2b85616f78174696BfAe775a1CB3',
    MummifiedBody: '0xD878eADd2DB8b4D659d4E38E2F5efE6A51832579',

    AlchemyStove: '0x2cC5f7304d84DC23F5d06eb646bcC45B6A3506Df',
    SoulStaker: '0x18FdFc11770BF807548C3cC84F7E1303daEd4F2D',
    Reborn: '0xcd68a597e1609889108c57738170166EE4994bbc',

    seaport: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
    SeaSwapper: '0xA74D4f6e92e70690E7D67d258a74CfF116659E9A',
  },
};

const CURRENT_CJC_ADDR =
  CJC_ALL_CONTRCT_ADDR[chainId] ?? CJC_ALL_CONTRCT_ADDR[DEFAULT_CHAIN_ID];

export const CURRENT_GRAPH_ENDPOINT = GRAPH_MAP[chainId];

export enum CjcNftTypes {
  ALL = 'all',
  HORSE = 'horse',
  JOCKEY = 'jockey',
  ITEMS = 'items',
  BOXES = 'boxes',
  SOUL = 'soul',
  HORSE_SOUL = 'horse_soul',
  JOCKEY_SOUL = 'jockey_soul',
  ITEM_SOUL = 'craftman_soul',
  XMETAL = 'x-metal',
  BODY = 'body',
}

export const NftAddr: Record<string, string> = {
  [CjcNftTypes.ITEM_SOUL]: CURRENT_CJC_ADDR.ItemSoul,
  [CjcNftTypes.HORSE]: CURRENT_CJC_ADDR.horse,
  [CjcNftTypes.BOXES]: CURRENT_CJC_ADDR.box,
  [CjcNftTypes.ITEMS]: CURRENT_CJC_ADDR.item,
  [CjcNftTypes.JOCKEY]: CURRENT_CJC_ADDR.jockey,
  [CjcNftTypes.JOCKEY_SOUL]: CURRENT_CJC_ADDR.JockeySoul,
  [CjcNftTypes.HORSE_SOUL]: CURRENT_CJC_ADDR.HorseSoul,
  [CjcNftTypes.XMETAL]: CURRENT_CJC_ADDR.AlloyX,
  [CjcNftTypes.BODY]: CURRENT_CJC_ADDR.MummifiedBody,
};

export const GstakeTokenList = {
  USDT: { symbol: 'USDT', tokenAddress: CURRENT_CJC_ADDR.usdt },
  CJC: { symbol: 'CJC', tokenAddress: CURRENT_CJC_ADDR.cjc },
  GRT: { symbol: 'GRT', tokenAddress: CURRENT_CJC_ADDR.grt },
  wstGRT: { symbol: 'wstGRT', tokenAddress: CURRENT_CJC_ADDR.wstgrt },
};

export const GSTAKE_CONTRACT = CURRENT_CJC_ADDR.gstake;
export const GSTAKE_FAUCET_CONTRACT = CURRENT_CJC_ADDR.faucet;
export const GRT_SALT = CURRENT_CJC_ADDR.grtSalt;
export const CROSS_CHAIN_SEAPORT_ADDRESS = CURRENT_CJC_ADDR.seaport;
export const SEAPORT_SWAPPER = CURRENT_CJC_ADDR.SeaSwapper;
export const CJC_PLAYGROUND_ADDRESS = CURRENT_CJC_ADDR.boxRouter;
export const BURNING_CONTRACT_ADDRESS = CURRENT_CJC_ADDR.AlchemyStove;
export const SOUL_STAKER_ADDRESS = CURRENT_CJC_ADDR.SoulStaker;
export const REBORN_CONTRACT = CURRENT_CJC_ADDR.Reborn;

export const SOULS_COLLECTION = [
  CjcNftTypes.HORSE_SOUL,
  CjcNftTypes.ITEM_SOUL,
  CjcNftTypes.JOCKEY_SOUL,
];
export const SOULS_BODY_METAL_COLLECTION = [
  CjcNftTypes.HORSE_SOUL,
  CjcNftTypes.ITEM_SOUL,
  CjcNftTypes.JOCKEY_SOUL,
  CjcNftTypes.XMETAL,
  CjcNftTypes.BODY,
];

export const NftNameMap = {
  [CjcNftTypes.HORSE_SOUL]: 'Horse Soul',
  [CjcNftTypes.JOCKEY_SOUL]: 'Jockey Soul',
  [CjcNftTypes.ITEM_SOUL]: 'Item Soul',
  [CjcNftTypes.XMETAL]: 'Alloy-X',
  [CjcNftTypes.BODY]: 'Mummified body',
  [CjcNftTypes.HORSE]: 'Horse',
  [CjcNftTypes.JOCKEY]: 'Jockey',
  [CjcNftTypes.ITEMS]: 'Items',
  [CjcNftTypes.BOXES]: 'Boxes',
  [CjcNftTypes.SOUL]: 'Soul',
};

export const ContranctToNftType = {
  [NftAddr[CjcNftTypes.HORSE].toLowerCase()]: CjcNftTypes.HORSE,
  [NftAddr[CjcNftTypes.BOXES].toLowerCase()]: CjcNftTypes.BOXES,
  [NftAddr[CjcNftTypes.ITEMS].toLowerCase()]: CjcNftTypes.ITEMS,
  [NftAddr[CjcNftTypes.JOCKEY].toLowerCase()]: CjcNftTypes.JOCKEY,
  [NftAddr[CjcNftTypes.HORSE_SOUL].toLowerCase()]: CjcNftTypes.HORSE_SOUL,
  [NftAddr[CjcNftTypes.ITEM_SOUL].toLowerCase()]: CjcNftTypes.ITEM_SOUL,
  [NftAddr[CjcNftTypes.JOCKEY_SOUL].toLowerCase()]: CjcNftTypes.JOCKEY_SOUL,
  [NftAddr[CjcNftTypes.XMETAL].toLowerCase()]: CjcNftTypes.XMETAL,
  [NftAddr[CjcNftTypes.BODY].toLowerCase()]: CjcNftTypes.BODY,
};

export const REBUILD_RULES = {
  [CjcNftTypes.BODY]: [
    {
      nftType: CjcNftTypes.JOCKEY_SOUL,
      result: CjcNftTypes.JOCKEY,
      level: 'same',
    },
    {
      nftType: CjcNftTypes.HORSE_SOUL,
      result: CjcNftTypes.HORSE,
      level: 'same',
    },
  ],
  [CjcNftTypes.XMETAL]: [
    {
      nftType: CjcNftTypes.JOCKEY_SOUL,
      result: CjcNftTypes.ITEMS,
      level: 'levelup',
    },
    {
      nftType: CjcNftTypes.HORSE_SOUL,
      result: CjcNftTypes.ITEMS,
      level: 'levelup',
    },
    {
      nftType: CjcNftTypes.ITEM_SOUL,
      result: CjcNftTypes.ITEMS,
      level: 'same',
    },
  ],
  [CjcNftTypes.JOCKEY_SOUL]: [
    {
      nftType: CjcNftTypes.BODY,
      result: CjcNftTypes.JOCKEY,
      level: 'same',
    },
    {
      nftType: CjcNftTypes.XMETAL,
      result: CjcNftTypes.ITEMS,
      level: 'levelup',
    },
  ],
  [CjcNftTypes.HORSE_SOUL]: [
    {
      nftType: CjcNftTypes.BODY,
      result: CjcNftTypes.HORSE,
      level: 'same',
    },
    {
      nftType: CjcNftTypes.XMETAL,
      result: CjcNftTypes.ITEMS,
      level: 'levelup',
    },
  ],
  [CjcNftTypes.ITEM_SOUL]: [
    {
      nftType: CjcNftTypes.XMETAL,
      result: CjcNftTypes.ITEMS,
      level: 'same',
    },
  ],
};

export const REBUILD_INTROS = {
  [CjcNftTypes.BODY]: {
    base: 'Reborn egg add a same rarity jockey or horse soul asset can rebuild a new jockey or horse asset.',
    [CjcNftTypes.JOCKEY_SOUL]:
      'Reborn egg and same rarity jockey soul can rebuild a new jockey asset.Please note that this operation is not reversible.',
    [CjcNftTypes.HORSE_SOUL]:
      'Reborn egg and same rarity horse soul can rebuild a new horse asset.Please note that this operation is not reversible.',
  },
  [CjcNftTypes.XMETAL]: {
    base: 'Rebuild alloy add a same rarity soul asset can rebuild a new item asset.',
    [CjcNftTypes.JOCKEY_SOUL]:
      'Rebuild alloy and same rarity jockey soul can rebuild a new item asset.Please note that this operation is not reversible.',
    [CjcNftTypes.HORSE_SOUL]:
      'Rebuild alloy and same rarity horse soul can rebuild a new item asset.Please note that this operation is not reversible.',
    [CjcNftTypes.ITEM_SOUL]:
      'Rebuild alloy and same rarity item soul can rebuild a new item asset.Please note that this operation is not reversible.',
  },
  [CjcNftTypes.JOCKEY_SOUL]: {
    base: 'Jockey’s soul add a same rarity reborn egg or alloy can rebuild a new jockey asset or a new item asset.',
    [CjcNftTypes.BODY]:
      'Jockey’s soul and same rarity rebuild egg can rebuild a new jockey asset.Please note that this operation is not reversible.',
    [CjcNftTypes.XMETAL]:
      'Jockey’s soul and same rarity rebuild alloy can rebuild a new item asset.Please note that this operation is not reversible.',
  },
  [CjcNftTypes.HORSE_SOUL]: {
    base: 'Horse’s soul add a same rarity reborn egg or alloy can rebuild a new horse asset or a new item asset.',
    [CjcNftTypes.BODY]:
      'Horse’s soul and same rarity rebuild egg can rebuild a new horse asset.Please note that this operation is not reversible.',
    [CjcNftTypes.XMETAL]:
      'Horse’s soul and same rarity rebuild alloy can rebuild a new item asset.Please note that this operation is not reversible.',
  },
  [CjcNftTypes.ITEM_SOUL]: {
    base: 'Item’s soul add a same rarity alloy can rebuild a new item asset.',
    [CjcNftTypes.XMETAL]:
      'Item’s soul and same rarity rebuild alloy can rebuild a new item asset.Please note that this operation is not reversible.',
  },
};

const NftTradeToken: Record<string, any> = {
  [CjcNftTypes.HORSE]: GstakeTokenList['CJC'],
  [CjcNftTypes.BOXES]: GstakeTokenList['USDT'],
  [CjcNftTypes.JOCKEY]: GstakeTokenList['CJC'],
  [CjcNftTypes.ITEMS]: GstakeTokenList['CJC'],
  [CjcNftTypes.SOUL]: GstakeTokenList['CJC'],
  [CjcNftTypes.HORSE_SOUL]: GstakeTokenList['CJC'],
  [CjcNftTypes.ITEM_SOUL]: GstakeTokenList['CJC'],
  [CjcNftTypes.JOCKEY_SOUL]: GstakeTokenList['CJC'],
  [CjcNftTypes.XMETAL]: GstakeTokenList['CJC'],
  [CjcNftTypes.BODY]: GstakeTokenList['CJC'],
};

const NftTypeMap: Record<string, number> = {
  [CjcNftTypes.HORSE]: ItemType.ERC721,
  [CjcNftTypes.BOXES]: ItemType.ERC1155,
  [CjcNftTypes.JOCKEY]: ItemType.ERC721,
  [CjcNftTypes.ITEMS]: ItemType.ERC721,
  [CjcNftTypes.SOUL]: ItemType.ERC1155,
  [CjcNftTypes.HORSE_SOUL]: ItemType.ERC1155,
  [CjcNftTypes.ITEM_SOUL]: ItemType.ERC1155,
  [CjcNftTypes.JOCKEY_SOUL]: ItemType.ERC1155,
  [CjcNftTypes.XMETAL]: ItemType.ERC1155,
  [CjcNftTypes.BODY]: ItemType.ERC1155,
};

export const NftProtocolMap = {
  [CjcNftTypes.HORSE]: 'ERC721',
  [CjcNftTypes.BOXES]: 'ERC1155',
  [CjcNftTypes.JOCKEY]: 'ERC721',
  [CjcNftTypes.ITEMS]: 'ERC721',
  [CjcNftTypes.SOUL]: 'ERC1155',
  [CjcNftTypes.HORSE_SOUL]: 'ERC1155',
  [CjcNftTypes.ITEM_SOUL]: 'ERC1155',
  [CjcNftTypes.JOCKEY_SOUL]: 'ERC1155',
  [CjcNftTypes.XMETAL]: 'ERC1155',
  [CjcNftTypes.BODY]: 'ERC1155',
};

export const NFTS_TO_SOULS = {
  [CjcNftTypes.HORSE]: [CjcNftTypes.HORSE_SOUL],
  [CjcNftTypes.ITEMS]: [CjcNftTypes.ITEM_SOUL],
  [CjcNftTypes.JOCKEY]: [CjcNftTypes.JOCKEY_SOUL],
};

export const useAllCjcNftAddr = (): string[] => {
  return Object.values(NftAddr).map((x) => x.toLowerCase());
};
export const useCjcNftAddr = (nftType: CjcNftTypes): string => {
  return NftAddr?.[nftType]?.toLowerCase() || '0x';
};
export const useCjcNftAddrMap = (nftType: CjcNftTypes): string[] => {
  if (nftType === CjcNftTypes.SOUL) {
    return SOULS_BODY_METAL_COLLECTION.map((x) => NftAddr[x]);
  }
  return [NftAddr?.[nftType]?.toLowerCase()] || ['0x'];
};
export const useRawCjcNftAddr = (nftType: CjcNftTypes): string => {
  return NftAddr?.[nftType] || '0x';
};
export const useCjcNftType = (nftType: CjcNftTypes): ItemType => {
  return NftTypeMap[nftType] || ItemType.ERC721;
};
export const getCjcNftTypeByContractAddr = (contractAddr: string): CjcNftTypes | null => {
  if (!contractAddr) {
    return null;
  }
  const isSoul = SOULS_COLLECTION.find((soul) =>
    isSameAddress(NftAddr[soul], contractAddr),
  );
  if (isSoul) {
    return isSoul;
  }
  return ContranctToNftType[contractAddr.toLowerCase()] || CjcNftTypes.HORSE;
};
export const useCjcNftTradeToken = (
  nftType: CjcNftTypes,
): { symbol: string; tokenAddress: string } => {
  return NftTradeToken[nftType];
};
