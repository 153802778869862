import { getParsedEthersError } from '@enzoferey/ethers-error-parser';

const ERROR_KEY_MAP: Record<string, string> = {
  TRANSACTION_RAN_OUT_OF_GAS: 'Transaction is ran out of gas',
  TRANSACTION_UNDERPRICED: 'Transaction is underpriced',
  REJECTED_TRANSACTION: 'User rejected transaction',
  CALL_REVERTED: 'Transaction failed',
  EXECUTION_REVERTED: 'Transaction failed',
  NONCE_TOO_LOW: 'Transaction nonce too low',
  INSUFFICIENT_FUNDS_FOR_GAS: 'Insufficient funds for gas, transaction failed',
  MAX_PRIORITY_FEE_PER_GAS_HIGHER_THAN_MAX_FEE_PER_GAS:
    'Transaction failed due to gas issues',
  MAX_FEE_PER_GAS_LESS_THAN_BLOCK_BASE_FEE: 'Transaction failed due to gas issues',
  USER_REJECTED: 'User rejected transaction',
  ACTION_REJECTED: 'User rejected transaction',
};

export function getErrorText(error: any, fallbackError = 'Transaction failed'): string {
  const ethersError = getParsedEthersError(error);

  if (!ethersError) {
    return error?.message ?? fallbackError;
  }

  let errorKey = ethersError.errorCode as string;

  if (ethersError?.errorCode === 'UNKNOWN_ERROR') {
    errorKey =
      ethersError.context === 'ACTION_REJECTED' ? 'ACTION_REJECTED' : 'UNKNOWN_ERROR';
  }

  if (ERROR_KEY_MAP?.[errorKey]) {
    return ERROR_KEY_MAP[errorKey];
  }

  return fallbackError;
}
