import styled from '@emotion/styled';
import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useState } from 'react';

import { ReactComponent as LogoSvg } from '@/static/img/gstake/nav_logo.svg';
import { wait } from '@/utils/retry';

const AppLoading: React.FC = () => {
  const [show, setShow] = useState(true);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    (async () => {
      // await wait(500);
      // setVisible(false);
      // await wait(1000);
      // setShow(false);

      const font = new FontFaceObserver('Alibaba');

      font
        .load()
        .then(
          async () => {
            console.log('Font is available');
          },
          function () {
            console.log('Font is not available');
          },
        )
        .finally(async () => {
          await wait(500);
          setVisible(false);
          await wait(1000);
          setShow(false);
        });
    })();
  }, []);

  return (
    <MaskWrapper visible={visible} show={show}>
      <AnimateWrap visible={visible}>
        <LogoSvg />
      </AnimateWrap>
    </MaskWrapper>
  );
};

export default AppLoading;

const MaskWrapper = styled.div<{ show: boolean; visible: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background: #0e0b14;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const AnimateWrap = styled.div<{ visible: boolean }>`
  transition: all 0.5s;
  transform: ${(props) => (props.visible ? 'scale(1)' : 'scale(3)')};
`;
