import styled from '@emotion/styled';
import Tooltip from 'rc-tooltip';
import React, { memo } from 'react';

interface TipsTextProps {
  placement?: string; // overlay position, defined in arrowPlacementMap
  overlayText: string | React.ReactNode; // overlay content, text or html node
  triggerNode?: string | React.ReactNode; // trigger text or element
  arrowContent?: React.ReactNode;
  triggerNodeStyle?: Record<string, unknown>; // custom trigger text style, style object
  triggerType?: 'hover' | 'click';
  overlayStyle?: {
    // custom overlay style, style object
    background?: string;
    maxWidth?: string;
  };
  overlayClassName?: string; // overlay class name
  prefix?: string | React.ReactNode; // prefix 与 suffix，主用于传入 icon
  suffix?: string | React.ReactNode;
  children?: string | React.ReactNode;
  className?: string;
  visible?: boolean;
  onVisibleChange?: (v: any) => void;
}

const autoAdjustOverflow = {
  adjustX: 1,
  adjustY: 1,
};
const arrowWidth = 12,
  horizontalArrowShift = 22;
// verticalArrowShift = 8
const targetOffset = [0, 0];
const builtinPlacements = {
  left: {
    points: ['cr', 'cl'],
    overflow: autoAdjustOverflow,
    offset: [-10, 0],
    targetOffset: targetOffset,
  },
  right: {
    points: ['cl', 'cr'],
    overflow: autoAdjustOverflow,
    offset: [10, 0],
    targetOffset: targetOffset,
  },
  top: {
    points: ['bc', 'tc'],
    overflow: autoAdjustOverflow,
    offset: [0, -10],
    targetOffset: targetOffset,
  },
  bottom: {
    points: ['tc', 'bc'],
    overflow: autoAdjustOverflow,
    offset: [0, 10],
    targetOffset: targetOffset,
  },
  topLeft: {
    points: ['bl', 'tl'],
    overflow: autoAdjustOverflow,
    offset: [-(horizontalArrowShift + arrowWidth), -10],
    targetOffset: targetOffset,
  },
  leftTop: {
    points: ['tr', 'tl'],
    overflow: autoAdjustOverflow,
    offset: [-10, 0],
    targetOffset: targetOffset,
  },
  topRight: {
    points: ['br', 'tr'],
    overflow: autoAdjustOverflow,
    offset: [0, -10],
    targetOffset: targetOffset,
  },
  rightTop: {
    points: ['tl', 'tr'],
    overflow: autoAdjustOverflow,
    offset: [10, 0],
    targetOffset: targetOffset,
  },
  bottomRight: {
    points: ['tr', 'br'],
    overflow: autoAdjustOverflow,
    offset: [0, 10],
    targetOffset: targetOffset,
  },
  rightBottom: {
    points: ['bl', 'br'],
    overflow: autoAdjustOverflow,
    offset: [10, 0],
    targetOffset: targetOffset,
  },
  bottomLeft: {
    points: ['tl', 'bl'],
    overflow: autoAdjustOverflow,
    offset: [0, 10],
    targetOffset: targetOffset,
  },
  leftBottom: {
    points: ['br', 'bl'],
    overflow: autoAdjustOverflow,
    offset: [-10, 0],
    targetOffset: targetOffset,
  },
};
const arrowPlacementMap: Record<string, string> = {
  right: 'borderRightColor',
  rightTop: 'borderRightColor',
  rightBottom: 'borderRightColor',

  top: 'borderTopColor',
  topLeft: 'borderTopColor',
  topRight: 'borderTopColor',

  left: 'borderLeftColor',
  leftTop: 'borderLeftColor',
  leftBottom: 'borderLeftColor',

  bottom: 'borderBottomColor',
  bottomLeft: 'borderBottomColor',
  bottomRight: 'borderBottomColor',
};

const TipsText = memo(
  ({
    // defaultStyle,
    placement = 'top',
    triggerNode = '',
    triggerType = 'hover',
    onVisibleChange,
    triggerNodeStyle = {},
    overlayText,
    overlayStyle = {
      background: '',
      maxWidth: '',
    },
    arrowContent,
    overlayClassName = '',
    className,
    visible,
  }: // prefix,
  // suffix,
  // children,
  TipsTextProps) => {
    const arrowColor = overlayStyle.background
      ? {
          [arrowPlacementMap[placement]]: overlayStyle.background,
        }
      : {
          [arrowPlacementMap[placement]]: 'rgb(40, 43, 50)',
        };
    const visibleControalProps = onVisibleChange ? { visible, onVisibleChange } : {};

    return (
      <StyledTooltip
        className={className}
        trigger={[triggerType]}
        // overlay={text}
        // visible={visible}
        placement={placement}
        // destroyTooltipOnHide={true}
        overlayClassName={overlayClassName}
        arrowContent={
          arrowContent || (
            <div style={{ ...arrowColor }} className="rc-tooltip-custom-arrow"></div>
          )
        }
        overlay={
          <div style={{ ...overlayStyle }} className="rc-tooltip-custom-inner">
            {overlayText}
          </div>
        }
        // onVisibleChange={onVisibleChange}
        {...visibleControalProps}
        transitionName="rc-tooltip-zoom"
        onPopupAlign={() => true}
        builtinPlacements={builtinPlacements}
      >
        <span style={{ ...triggerNodeStyle }} className="tooltip-title">
          {triggerNode}
        </span>
      </StyledTooltip>
    );
  },
);

TipsText.displayName = 'TipsText';

const StyledTooltip = styled(Tooltip)<{ className?: string }>`
  display: inline-block;
  cursor: pointer;
`;

export default TipsText;
