// import 'chart.js/auto';

import styled from '@emotion/styled';
// import {
//   CategoryScale,
//   Chart as ChartJS,
//   Legend,
//   LinearScale,
//   LineElement,
//   PointElement,
//   Title,
//   Tooltip,
// } from 'chart.js';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingMask from '@/components/common/LodaingMask';
import {
  BinanceData,
  formatBinanceData,
  IntervalTypes,
  useGrtHistory,
  useGrtUsdHistory,
  useGstakeStats,
} from '@/hooks/queries/useGstakeGraph';
import { selectPendingEndResult } from '@/store/globalConfig';
import { useActiveWeb3React } from '@/web3/WalletProvider';
import ChartWithLabel from '@/components/enhanced/LightCharts/ChartViewWithLabel';
import Decimal from 'decimal.js';
import { getPrecisionedAmount } from '@/utils/format';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// );

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'wstGRT Price',
//       data: labels.map(() => faker.datatype.number({ min: 5, max: 35 })),
//       borderColor: '#662B70',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     // {
//     //   label: 'Dataset 2',
//     //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//     //   borderColor: 'rgb(53, 162, 235)',
//     //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     // },
//   ],
// };

const IntervalMap = [
  IntervalTypes['1D'],
  IntervalTypes['3D'],
  IntervalTypes['7D'],
  IntervalTypes['1M'],
];

// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       enabled: true,
//       callbacks: {
//         title: (items: any) => {
//           const item = items[0];
//           return `${item.label} 08:00:00`;
//         },
//       },
//     },
//   },
//   scales: {
//     y: {
//       beginAtZero: false,
//       ticks: {
//         min: 1,
//         max: 35,
//         stepSize: 0.5,
//         color: '#fff',
//         callback: function (value: string) {
//           return `${value}`;
//         },
//       },
//     },
//     x: {
//       border: {
//         display: true,
//         color: '#2F2F30',
//       },
//       ticks: {
//         color: '#fff',
//       },
//     },
//   },
// };

function App() {
  const [curInterval, setCurInterval] = useState<IntervalTypes>(IntervalTypes['1D']);
  const [curPriceType, setCurPriceType] = useState<'USD' | 'GRT'>('USD');
  const { currentChainId } = useActiveWeb3React();
  const pendingEndResult = useSelector(selectPendingEndResult);
  const { data: gstakeStats } = useGstakeStats(pendingEndResult);
  const { data: grtData, isFetching } = useGrtHistory({
    key: pendingEndResult + currentChainId,
  });

  const historyDays = useMemo(() => {
    if (!grtData?.length) {
      return 0;
    }

    return +dayjs().diff(dayjs.unix(parseInt(grtData[0].timestamp)), 'day') + 1;
  }, [grtData]);

  const { data: usdHistoryData } = useGrtUsdHistory(
    pendingEndResult + curInterval,
    {
      interval: curInterval,
    },
    { enabled: +historyDays > 0 },
  );

  const chartData = useMemo(() => {
    if (!grtData?.length) {
      return [];
    }
    if (curPriceType === 'USD') {
      return grtData.map((x) => [
        dayjs(x.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss'),
        +x.usdRate,
      ]);
    }
    return grtData.map((x) => [
      dayjs(x.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss'),
      +x.usdRate,
    ]);
  }, [grtData, curPriceType]);

  const usdPriceChartData = useMemo(() => {
    if (!usdHistoryData?.length || !grtData?.length) {
      return [];
    }

    const filteredData = usdHistoryData.map((item: BinanceData) => {
      const { value, time } = formatBinanceData(item);
      if (value && time) {
        return [
          dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
          +new Decimal(value).toFixed(4, Decimal.ROUND_DOWN),
        ];
      }
      return null;
    });

    return filteredData.filter(Boolean);
  }, [grtData, usdHistoryData]);

  const data = useMemo(() => {
    if (curPriceType === 'GRT') {
      return chartData;
    }
    return usdPriceChartData;
  }, [chartData, usdPriceChartData, curPriceType]);

  const WstGRTprice = useMemo(() => {
    if (!gstakeStats?.exchangeRate) {
      return '-';
    }
    return getPrecisionedAmount(1 / +gstakeStats?.exchangeRate + '', 'wstGRT', 4);
  }, [gstakeStats]);

  return (
    <ChartWrap>
      {isFetching && !chartData.length && <LoadingMask size={200} />}

      <IntervalSelection>
        {IntervalMap.map((time) => {
          return (
            <div
              onClick={() => {
                setCurInterval(time);
              }}
              key={`interval_${time}`}
              className={clsx('single-interval', time === curInterval && 'isActive')}
            >
              {time.toUpperCase()}
            </div>
          );
        })}
      </IntervalSelection>
      {/* <Line ref={chartRef} options={options as any} data={data} /> */}

      <ChartWithLabel
        onPriceSubscribe={undefined}
        timeDefaultLabel={'Time'}
        isFetching={false}
        data={data as any}
        interval={curInterval}
        WstGRTprice={WstGRTprice}
      >
        <div></div>
      </ChartWithLabel>
    </ChartWrap>
  );
}

const ChartWrap = styled.div`
  position: relative;
  background: #282830;
  padding: 0 16px;
  height: 350px;

  .label-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      font-family: Alibaba PuHuiTi 2;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #3a9670;
      text-align: right;
      margin-right: 20px;
    }
  }
`;

const IntervalSelection = styled.div`
  position: absolute;
  left: 20px;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 999;

  .single-interval {
    font-family: Alibaba PuHuiTi 2;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    margin-right: 10px;
    color: #89899b;
    cursor: pointer;

    &.isActive {
      color: #45f0af;
    }
  }
`;

export default App;
