import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import ButtonLoading from '../enhanced/ButtonLoading';

interface ButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  children?: React.ReactNode;
  className?: string;
  loadingElement?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
}

const CustomButton = ({
  loading,
  disabled,
  onClick,
  children,
  className,
  loadingElement,
  type,
}: ButtonProps) => {
  const handleClick = useCallback(
    (e: any) => {
      if (loading || disabled) {
        return;
      }
      onClick && onClick(e);
    },
    [loading, disabled, onClick],
  );

  return (
    <ButtonWrap
      type={type}
      className={`${className} ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {loading && <>{loadingElement ? loadingElement : <ButtonLoading />}</>}
      {children}
    </ButtonWrap>
  );
};

CustomButton.displayName = 'Custom Button';

export default CustomButton;

export const loadingCircles = () => keyframes`
        100% {
            transform: rotate(-360deg);
        }
`;

const ButtonWrap = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    cursor: not-allowed;
  }
  &:focus-visible {
    border: none;
    outline: none;
  }

  > .loading-svg {
    animation: ${() => loadingCircles()} 1s infinite linear;
    margin-right: 12px;
  }
`;
