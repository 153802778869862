import 'rc-dropdown/assets/index.css';

import styled from '@emotion/styled';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import qs from 'query-string';
import Menu from 'rc-menu';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useDisconnect } from 'wagmi';

import useAlert from '@/hooks/useAlert';
import { GstakeTokenList } from '@/hooks/useCjcNftAddr';
import WalletPng from '@/static/img/gstake/connect_button.png';
import WalletLgPng from '@/static/img/gstake/connect_button_lg.png';
import { ReactComponent as CopySvg } from '@/static/img/luckywins/wallet_copy.svg';
import MetamaskPng from '@/static/img/wallet_metamask.png';
import WalletConnectPng from '@/static/img/walletconnect.png';
import { updateAccountModalVisible } from '@/store/globalConfig';
import { selectSwitchPending } from '@/store/globalConfig';
import { getPrecisionedAmount } from '@/utils/format';
import { getCurrentChainId } from '@/web3/constants';
import { formatAddress } from '@/web3/format';
import { useActiveWeb3React } from '@/web3/WalletProvider';
import useBalance from '@/hooks/useBalance';
export enum ConnectButtonPositionType {
  HEADER = 'header',
  CONTENT = 'content',
}

export const WalletIconPng: Record<string, JSX.Element> = {
  metaMask: <img src={MetamaskPng} alt="" />,
  walletconnect: <img src={WalletConnectPng} alt="" />,
};

const CopyButton = ({ address }: { address: string }) => {
  const { setAlert } = useAlert();
  return (
    <CopyToClipboard text={address}>
      <WalletButton onClick={() => setAlert({ type: 'success', message: 'Copied' })}>
        <CopySvg />
        Copy Address
      </WalletButton>
    </CopyToClipboard>
  );
};

const GstakeConnectButton = memo(
  ({
    type,
    children,
    ...props
  }: {
    type: ConnectButtonPositionType;
    onClick?: (v?: any) => void;
    children?: JSX.Element | string;
  }) => {
    return (
      <StyledConnectButton className={type} {...props}>
        {type === ConnectButtonPositionType.HEADER && <img src={WalletPng} alt="" />}
        {type === ConnectButtonPositionType.CONTENT && <img src={WalletLgPng} alt="" />}
        {children}
      </StyledConnectButton>
    );
  },
);

GstakeConnectButton.displayName = 'GstakeConnectButton';

const CustomConnectButton: React.FC<{
  type: ConnectButtonPositionType;
  showWalletInfo?: boolean;
}> = ({ type, showWalletInfo }) => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const [desiredChainId, setDesiredChainId] = useState(getCurrentChainId());
  const { web3Provider, isAuthenticated, account } = useActiveWeb3React();
  const [curWallet, setCurWallet] = useState('');
  const { disconnect } = useDisconnect();
  const switchChainPending = useSelector(selectSwitchPending);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const { targetChain = getCurrentChainId() } = qs.parse(window.location.search);
    localStorage.setItem('gstakeChainId', targetChain as string);
    setDesiredChainId(+targetChain!);
  }, []);

  const { grtValue } = useBalance();

  useEffect(() => {
    if (isAuthenticated) {
      const walletType = localStorage.getItem('wagmi.wallet') || '';
      setCurWallet(walletType.replaceAll('"', ''));
    }
  }, [isAuthenticated]);

  const showAccountModal = () => {
    dispatch(updateAccountModalVisible(true));
  };

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        // openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted;
        const connected = ready && account && chain;

        console.log(ready, account, connected, chain, isAuthenticated, 'chexk status');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (switchChainPending) {
                return <GstakeConnectButton type={type}>Switching</GstakeConnectButton>;
              }
              if (!connected) {
                return (
                  <GstakeConnectButton type={type} onClick={openConnectModal}>
                    Connect Wallet
                  </GstakeConnectButton>
                );
              }
              if (chain?.unsupported) {
                return (
                  <GstakeConnectButton type={type} onClick={openChainModal}>
                    Wrong network
                  </GstakeConnectButton>
                );
              }
              // if (authenticatStatus === AuthStatusMap.Authenticating) {
              //   return (
              //     <GstakeConnectButton type={type} onClick={openChainModal}>
              //       Authenticating
              //     </GstakeConnectButton>
              //   );
              // }
              if (isAuthenticated && showWalletInfo) {
                return (
                  <HeaderWrap>
                    <HeaderWalletInfo onClick={showAccountModal}>
                      <div className="cjc-value">
                        {getPrecisionedAmount(grtValue, 'GRT')} GRT
                      </div>

                      <InfoWrap>
                        <div className="wallet-addr">
                          {formatAddress(account?.address)}
                        </div>

                        {WalletIconPng?.[curWallet] ?? (
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            style={{ width: 12, height: 12 }}
                          />
                        )}
                      </InfoWrap>
                    </HeaderWalletInfo>
                  </HeaderWrap>
                );
              }
              return <GstakeConnectButton type={type}>Loading</GstakeConnectButton>;
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default CustomConnectButton;

const StyledConnectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
  /* margin-left: 12px; */
  &:hover {
    opacity: 1;
  }
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &.header {
    width: 152px;
    height: 48px;
    color: #fff;
    font-family: 'Alibaba';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.content {
    width: 420px;
    height: 56px;
    color: #fff;
    font-family: 'Alibaba';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderWalletInfo = styled.div`
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 1px solid rgba(59, 53, 82, 0.4);
  background: rgba(35, 31, 54, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: #fff;
  font-family: 'Alibaba';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  > .inner {
    width: 130px;
    height: 42px;
    background: #262626;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-right: 16px;
    border-radius: 42px;

    > img {
      width: 28px !important;
      height: 28px !important;
    }

    .cjc-value {
      font-family: 'Alibaba';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #dedede;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .dropdown-arrow {
      width: 24px;
      height: 24px;
      margin-left: 4px;
      transition: all 0.5s;

      &.reverse {
        transform: rotate(180deg);
      }
    }
  }
`;

const NetworkOption = styled.div`
  color: #fff;
  font-family: 'Alibaba';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    margin-right: 8px;
  }

  &:hover {
    background: #2e264a;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoWrap = styled.div`
  width: 130px;
  height: 30px;
  border-radius: 64px;
  background: #0d0b14;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 16px;
  border-radius: 42px;
  margin-left: 8px;

  > img {
    width: 24px !important;
    height: 24px !important;
  }

  .wallet-addr {
    color: #c8c8c9;
    font-family: 'Alibaba';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 8px;
  }

  .cjc-value {
    font-family: 'Alibaba';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #dedede;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .dropdown-arrow {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    transition: all 0.5s;

    &.reverse {
      transform: rotate(180deg);
    }
  }
`;

const NetworkMenu = styled(Menu)`
  width: 200px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px;

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 8px;
    margin-left: 6px;
    margin-top: 6px;
    &:hover {
      color: #ffffff !important;
    }
  }
`;

const WalletButton = styled.div`
  width: 100%;
  height: 40px;
  background: #455067;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  font-family: 'Alibaba';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  margin-top: 8px;
`;
