import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Dashboard from '@/pages/Dashboard';
import Index from '@/pages/Index';
import Main from '@/pages/Main';
import WithdrawRoutes from '@/pages/Withdrawal/WithdrawRoutes';
// import Dashboard from 'pages/Dashboard'

const AppRoutes = () => {
  return (
    <Suspense fallback={<>loading</>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}>
            <Route index element={<Main />} />
            <Route path="withdrawal/*" element={<WithdrawRoutes />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
