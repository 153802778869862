/* eslint-disable react/destructuring-assignment, react/state-in-constructor */
// eslint-disable-next-line max-classes-per-file
import styled from '@emotion/styled';
import { PureComponent } from 'react';
import { ConfirmButton } from '../enhanced/GstakeModal';

type ErrorBoundaryProps = {
  onError?: (error: Error, componentStack: string | null) => void;
  children?: JSX.Element;
};

type ErrorBoundaryState = { error: Error | null };

class ErrorBoundaryBase extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  // eslint-disable-next-line react/no-unused-state
  override state: { error: Error | null } = { error: null };

  override componentDidCatch(
    error: Error,
    // Loosely typed because it depends on the React version and was
    // accidentally excluded in some versions.
    errorInfo?: { componentStack?: string | null },
  ) {
    this.props?.onError?.(error, errorInfo?.componentStack || null);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ error });
  }

  override render() {
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

class ErrorBoundary extends ErrorBoundaryBase {
  override render() {
    if (this.state.error) {
      return (
        // The component has to be unmounted or else it would continue to error
        <ErrorPageWrap>
          <div>GStake Encountered An Error</div>
          <div className="error-text" style={{ alignItems: 'center' }}>
            We apologize for the error encountered. Please provide us with feedback so
            that we can resolve the issue.
          </div>

          <ConfirmButton
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload Page
          </ConfirmButton>
        </ErrorPageWrap>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export { ErrorBoundaryBase };

const ErrorPageWrap = styled.div``;
