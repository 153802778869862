import styled from '@emotion/styled';
import { memo, useEffect, useRef } from 'react';

import { useActiveWeb3React } from '@/web3/WalletProvider';

import CustomButton from '../common/Button';
import CustomModal, { ModalProps } from '../common/CustomModal';

interface ConfirmButtonProps {
  full?: boolean;
}

const GstakeModal = memo(
  ({
    visible,
    onCancel,
    className,
    loading,
    maskClosable,
    afterClose,
    closable = true,
    children,
  }: ModalProps) => {
    const lastAccountRef = useRef('');

    const { account, isActive } = useActiveWeb3React();

    useEffect(() => {
      if (!visible) {
        lastAccountRef.current = '';
      }
      if (visible && !lastAccountRef.current) {
        lastAccountRef.current = account as string;
      }
    }, [visible]);

    return (
      <GStakeModalWrap
        visible={visible}
        width={898}
        onCancel={onCancel}
        className={className}
        loading={loading}
        afterClose={afterClose}
        maskClosable={maskClosable}
        // style={style}
      >
        {closable && (
          <CloseBtn onClick={() => onCancel()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
                stroke="#CDCCCF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.7715 12.2285L12.229 19.771"
                stroke="#CDCCCF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2285 12.2285L19.771 19.771"
                stroke="#CDCCCF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CloseBtn>
        )}
        {children}
      </GStakeModalWrap>
    );
  },
);

GstakeModal.displayName = 'GstakeModal';

export default GstakeModal;

const GStakeModalWrap = styled(CustomModal)`
  /* height: ${(props) => (props.loading ? '300px' : 'auto')} !important; */
  min-height: 250px;
  padding: 30px 20px;
  border-radius: 16px;
  border: 1px solid #3a966f;
  overflow: hidden;
  background: linear-gradient(244deg, #0f1318 0%, #101c1a 100%);

  &:focus-visible {
    outline: none;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 44px 56px;

  .account-title {
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 61px;
    color: #ffffff;
    margin-bottom: 24px;
  }
  .account-sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.4;
    margin-bottom: 16px;
  }
  .account-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
  }
`;

export const ConfirmButton = styled(CustomButton)<ConfirmButtonProps>`
  width: ${(props) => (props.full ? '100%' : '163px')};
  height: 50px;
  border-radius: 4px;
  background: ${(props) =>
    props.disabled
      ? 'rgba(103, 111, 129, 0.5) !important'
      : 'linear-gradient(122deg, #582EF8 0%, #9B4AF6 100%)'};
  box-shadow: ${(props) =>
    props.disabled ? 'none !important' : '0px 12px 24px rgba(138, 238, 110, 0.1)'};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

export const CloseBtn = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
