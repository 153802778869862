import styled from '@emotion/styled';
import clsx from 'clsx';

import { ReactComponent as PagiEndSvg } from '@/static/img/luckywins/page_end.svg';
import { ReactComponent as PagiRightSvg } from '@/static/img/luckywins/page_right.svg';

interface PaginatorProps {
  current: number;
  onChange: (v: number) => void;
  loading: boolean;
  hasNext: boolean;
}

const Paginator: React.FC<PaginatorProps> = ({ current, onChange, loading, hasNext }) => {
  const handleNext = () => {
    if (loading) {
      return;
    }
    onChange(current + 1);
  };
  const handlePrev = () => {
    if (loading || current === 0) {
      return;
    }
    onChange(current - 1);
  };
  return (
    <PaginatorWrapper>
      <LinkWrap
        onClick={handlePrev}
        className={clsx((loading || current === 0) && 'disabled', 'left')}
      >
        <PagiRightSvg />
      </LinkWrap>
      <span>Page</span>
      <PageNumWrap>
        <PageNum className="current">{current + 1}</PageNum>
      </PageNumWrap>
      <LinkWrap
        onClick={handleNext}
        className={clsx((loading || !hasNext) && 'disabled')}
      >
        <PagiRightSvg />
      </LinkWrap>
    </PaginatorWrapper>
  );
};

export default Paginator;

const PaginatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Alibaba';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #524875;
    margin-left: 16px;
    margin-right: 4px;

    &.right {
      margin-right: 16px;
      margin-left: 4px;
    }
  }
`;

const LinkWrap = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.8;
  border: 1px solid #524875;
  border-radius: 2px;

  &:hover {
    opacity: 1;
  }

  &.left {
    > svg {
      transform: rotateY(180deg);
    }
  }

  &.disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
`;

const PageNumWrap = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0.8;
  border-radius: 2px;
  background: #a897e3;
  color: #2c2448 !important;
  margin-right: 12px;
`;
const PageNum = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: 'Alibaba';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  border: 1px solid #b19f81;
  border-radius: 2px;

  &.current {
    color: #2c2448;
  }
`;

const NavButton = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 56px;
  height: 30px;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? '#262931' : '#3C4350')};
  opacity: ${(props) => (props.disabled ? '1' : '0.8')};

  &:hover {
    opacity: 1;
  }

  > svg {
    path {
      fill: ${(props) => (props.disabled ? '#828387' : '#fff')};
    }
  }
`;
