import { Contract } from '@ethersproject/contracts';
import { useEffect, useState } from 'react';

import useAlert from '@/hooks/useAlert';
import FAUCET_ABI from '@/web3/abi/faucet.json';
import { useActiveWeb3React } from '@/web3/WalletProvider';

import { GSTAKE_FAUCET_CONTRACT } from '../useCjcNftAddr';

export { FAUCET_ABI };

export function useFaucetContract() {
  const { web3Provider, account } = useActiveWeb3React();
  const [faucetContract, setFaucetContract] = useState<Contract | null>(null);
  const { setAlert } = useAlert();

  useEffect(() => {
    if (!web3Provider) {
      return;
    }
    const faucetContract = new Contract(
      GSTAKE_FAUCET_CONTRACT,
      FAUCET_ABI,
      web3Provider.getSigner(),
    );
    setFaucetContract(faucetContract);
  }, [web3Provider]);

  const faucetGrt = async () => {
    if (!faucetContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }
    const estimateGas = await faucetContract.estimateGas.requestTokens();
    // const updatedGas = parseInt(Number(estimateGas) * 1.3 + '');
    const result = await faucetContract.requestTokens({
      gasLimit: Number(estimateGas),
    });
    return result;
  };

  const faucetEth = async () => {
    if (!faucetContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }

    const estimateGas = await faucetContract.estimateGas.requestEth();
    // const updatedGas = parseInt(Number(estimateGas) * 1.3 + '');
    const result = await faucetContract.requestEth({
      gasLimit: Number(estimateGas),
    });
    return result;
  };

  const getLastGrtAccess = async () => {
    if (!faucetContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }
    const result = await faucetContract.lastAccessTimeToken(account);
    return result;
  };

  const getLastEthAccess = async () => {
    if (!faucetContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }
    const result = await faucetContract.lastAccessTimeEth(account);
    return result;
  };

  return {
    faucetContract,
    faucetGrt,
    faucetEth,
    getLastGrtAccess,
    getLastEthAccess,
  };
}
