import styled from '@emotion/styled';
import { useEffect } from 'react';
import tw from 'twin.macro';

import AssetsRecord, { RecordPanel } from './components/AssetsRecord';
import RewardHistory from './components/History';
import PriceCharts from './components/PriceCharts';

const Reward: React.FC = () => {
  useEffect(() => {
    document.title = 'Track your GRT staking rewards';
  }, []);

  return (
    <RewardWrapper>
      <RecordPanel height={400}>
        <PriceCharts />
      </RecordPanel>

      {/* <AssetsRecord /> */}

      <RewardHistory />
    </RewardWrapper>
  );
};

export default Reward;

const RewardWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
`;

const TitleWrap = styled.div`
  ${tw`flex flex-col justify-center items-center`}
  margin-bottom: 48px;

  .title {
    color: #fff;
    font-family: 'Alibaba';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-title {
    color: #cacbcd;
    font-family: 'Alibaba';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const AddressLine = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid #3a3f57;
  background: #0f0e1e;
  padding: 0 24px;
  color: #312f4a;
  font-family: 'Alibaba';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
