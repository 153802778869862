import styled from '@emotion/styled';
import _capitalize from 'lodash/capitalize';
// import Lottie from 'lottie-react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDisconnect } from 'wagmi';

import { ModalProps } from '@/components/common/CustomModal';
import { WalletIconPng } from '@/components/enhanced/ConnectButton';
import GstakeModal from '@/components/enhanced/GstakeModal';
import useAlert from '@/hooks/useAlert';
// import LoadingPng from '@/static/img/gstake/loading_circle.gif';
import { ReactComponent as CopySvg } from '@/static/img/gstake/icon_copy.svg';
import { ReactComponent as ScanSvg } from '@/static/img/gstake/icon_scan.svg';
import { capitalize } from '@/utils/format';
import { CHAINS } from '@/web3/constants';
import { formatAddress } from '@/web3/format';
import { useActiveWeb3React } from '@/web3/WalletProvider';

const AccountModal = memo(({ visible, onCancel }: ModalProps) => {
  const { currentChainId } = useActiveWeb3React();
  const [curWallet, setCurWallet] = useState('');
  const { setAlert } = useAlert();

  const { disconnect } = useDisconnect();
  const { isActive, account } = useActiveWeb3React();

  const handleDisconnect = () => {
    disconnect();
    onCancel();
  };

  useEffect(() => {
    if (isActive) {
      const walletType = localStorage.getItem('wagmi.wallet') || '';
      setCurWallet(walletType.replaceAll('"', ''));
    }
  }, [isActive]);

  const getScanUrl = useCallback(
    (chainId: number) => {
      const _targetChainId = chainId || currentChainId;
      if (CHAINS?.[_targetChainId] && CHAINS?.[_targetChainId]?.blockExplorerUrls) {
        return CHAINS?.[_targetChainId]?.blockExplorerUrls?.[0].replace(
          '/tx',
          '/address',
        );
      }
      return '';
    },
    [currentChainId],
  );

  return (
    <AccountModalWrap visible={visible} width={600} onCancel={onCancel}>
      <div className="inner">
        <div className="title">Account</div>
        <div className="content-wrap">
          <DisconnectButton onClick={handleDisconnect}>
            <span>Disconnect</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="24"
              viewBox="0 0 100 24"
              fill="none"
            >
              <path
                d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H88.6748C88.7805 0.5 88.8834 0.533477 88.9689 0.595633L98.8823 7.80535C99.2704 8.08761 99.5 8.53854 99.5 9.01845V20C99.5 21.933 97.933 23.5 96 23.5H4C2.06701 23.5 0.5 21.933 0.5 20V4Z"
                stroke="white"
              />
            </svg>
          </DisconnectButton>

          <div className="connect-info">Connected with {capitalize(curWallet)}</div>

          <div className="address-line">
            {WalletIconPng?.[curWallet]} {formatAddress(account)}
          </div>

          <div className="action-line">
            <CopyToClipboard text={account as string}>
              <SingleAction
                className="single-action"
                onClick={() => setAlert({ type: 'success', message: 'Copied' })}
              >
                <CopySvg />
                Copy Address
              </SingleAction>
            </CopyToClipboard>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${getScanUrl(currentChainId)}${account}`}
            >
              <SingleAction>
                <ScanSvg />
                View on Etherscan
              </SingleAction>
            </a>
          </div>
        </div>
      </div>
    </AccountModalWrap>
  );
});

AccountModal.displayName = 'AccountModal';

export default AccountModal;

const DisconnectButton = styled.div`
  width: 100px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: 'Alibaba';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 24px;

  > span {
    position: relative;
    z-index: 1;
  }
  > svg {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const SingleAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #118eff;
  font-family: 'Alibaba';
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-right: 48px;
  cursor: pointer;

  > svg {
    margin-right: 8px;
  }
`;

const AccountModalWrap = styled(GstakeModal)`
  width: 500px !important;
  border-radius: 16px !important;
  min-height: 324px;
  padding: 60px 40px 40px;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
      width: 100%;
      text-align: left;
    }

    .content-wrap {
      border-radius: 8px;
      border: 1px solid #2f3f4a;
      background: #13181e;
      height: 154px;
      width: 100%;
      padding: 24px 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      position: relative;

      .connect-info {
        color: #999;
        font-family: 'Alibaba';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
      }

      .address-line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #fff;
        font-family: 'Alibaba';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24px;

        > img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      .action-line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
`;
