import styled from '@emotion/styled';
import clsx from 'clsx';
import Decimal from 'decimal.js';
import _capitalize from 'lodash/capitalize';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TipsText from '@/components/common/Tooltip';
import ButtonLoading from '@/components/enhanced/ButtonLoading';
import LoginButton, {
  ConnectButtonPositionType,
} from '@/components/enhanced/ConnectButton';
import { useGstakeContract } from '@/hooks/queries/useGstakeContract';
import { useGstakeStats, useGstakeUserInfo } from '@/hooks/queries/useGstakeGraph';
import useAlert from '@/hooks/useAlert';
import { GSTAKE_CONTRACT, GstakeTokenList } from '@/hooks/useCjcNftAddr';
import { ConfirmButton, StyledPanel } from '@/pages/Main/components/MainPanel';
import TransactionModal, {
  TransactionProps,
  TransactionStatus,
} from '@/pages/Main/components/TransactionModal';
import { ReactComponent as CheckedSvg } from '@/static/img/gstake/claim_checked.svg';
import { ReactComponent as LinkSvg } from '@/static/img/gstake/claim_link.svg';
import { ReactComponent as UncheckedSvg } from '@/static/img/gstake/claim_unchecked.svg';
import { ReactComponent as ClaimSvg } from '@/static/img/gstake/icon_claim.svg';
import { ReactComponent as PendingSvg } from '@/static/img/gstake/icon_pending_claim.svg';
import { ReactComponent as ProcessingSvg } from '@/static/img/gstake/icon_processing.svg';
import { selectPendingEndResult, updatePendingEndResult } from '@/store/globalConfig';
import {
  ClaimingToken,
  selectClaimableItemAmount,
  selectTokenClaimed,
  updateTokenClaimed,
} from '@/store/user';
import { CHAINS } from '@/web3/constants';
import { useActiveWeb3React } from '@/web3/WalletProvider';

import WithdrawStatusCard, {
  WithdrawStatus,
  WithdrawStatusString,
  WithdrawStatusTitle,
} from './WithdrawStatusCard';
import useBalance from '@/hooks/useBalance';
import { getErrorText } from '@/utils/getErrorText';
import dayjs from 'dayjs';
import { convertMillisecondsToTime } from '@/utils/format';

enum ClaimStatus {
  READY = 'ready',
  PROCESSING = 'processing',
  PENDING = 'pending',
}

const ClaimPanel = memo((props: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [transModalProps, setTransModalProps] = useState<TransactionProps>({});
  const { isActive, account, web3Provider, currentChainId } = useActiveWeb3React();
  const pendingEndKey = useSelector(selectPendingEndResult);
  const tokenClaimed = useSelector(selectTokenClaimed);
  const tokenPendingClaim = useSelector(selectClaimableItemAmount);

  const { setAlert } = useAlert();

  const { wstgrtValue } = useBalance();

  const { claim } = useGstakeContract();

  const { data: userInfo } = useGstakeUserInfo(account as string, pendingEndKey, {
    enabled: isActive,
    refetchInterval: 5000,
    keepPreviousData: true,
  });

  console.log(userInfo, 'check user info');

  const { data: gstakeStats } = useGstakeStats(pendingEndKey);

  const [ids, setIds] = useState<string[]>([]);

  const selectedGrtValue = useMemo(() => {
    if (!ids.length || !userInfo?.tokens?.length) {
      return 0;
    }

    const totalGrt = userInfo.tokens.reduce((s, x) => {
      if (ids.includes(x.id)) {
        return s + +x.amountOfGRT;
      }
      return s;
    }, 0);

    return new Decimal(totalGrt).toFixed(2, Decimal.ROUND_DOWN);
  }, [ids, userInfo]);

  const handleCheck = (item: any) => {
    if (item.status !== WithdrawStatus.CLAIMABLE) {
      return;
    }

    const _ids = ids.includes(item.id)
      ? ids.filter((x) => x !== item.id)
      : [...ids, item.id];
    setIds(_ids);
  };

  const handleClaim = async () => {
    if (!web3Provider) {
      return;
    }

    setTransModalProps({
      status: TransactionStatus.WAITING_CONFIRM,
      content: `You are now claiming ${selectedGrtValue} GRT`,
      subContent: 'Proccessing your request',
      footer: 'Confirm request in your wallet',
    });

    try {
      setLoading(true);
      const result = await claim(ids);

      if (!result.hash) {
        setTransModalProps({});
      }

      dispatch(updateTokenClaimed(ids.map((x) => Number(x))));

      setTransModalProps({
        status: TransactionStatus.PENDING,
        content: `You are now claiming ${selectedGrtValue} GRT`,
        subContent: 'Awaiting block confirmation',
        hash: result.hash,
      });

      await result.wait();

      dispatch(updatePendingEndResult(Math.random()));

      setTimeout(() => {
        dispatch(updatePendingEndResult(Math.random()));
      }, 4000);

      setTransModalProps({
        status: TransactionStatus.COMPLETE,
        content: `${selectedGrtValue} GRT has been claimed`,
        subContent: 'Claiming opration was successful',
        hash: result.hash,
      });
    } catch (error: any) {
      setAlert({ message: getErrorText(error), type: 'error' });
      setLoading(false);
      setTransModalProps({});
      setIds([]);
    } finally {
      setLoading(false);
      setIds([]);
    }
  };

  const getScanUrl = useCallback(
    (chainId: number) => {
      const _targetChainId = chainId || currentChainId;
      if (CHAINS?.[_targetChainId] && CHAINS?.[_targetChainId]?.blockExplorerUrls) {
        return CHAINS?.[_targetChainId]?.blockExplorerUrls?.[0].replace('/tx', '/token');
      }
      return '';
    },
    [currentChainId],
  );

  const displayWithdrawRecords = useMemo(() => {
    const localStoredTokens = tokenPendingClaim.filter(
      (x) => !tokenClaimed.includes(Number(x.id)),
    );

    if (!userInfo?.tokens) {
      return localStoredTokens;
    }

    const fetchedTokens = userInfo?.tokens.filter(
      (x) =>
        x.status !== WithdrawStatus.COMPLETED && !tokenClaimed.includes(Number(x.id)),
    );

    const finalTokens = localStoredTokens.reduce((s, x) => {
      if (!fetchedTokens.some((t) => Number(t.id) === Number(x.id))) {
        return [...s, x];
      }
      return s;
    }, fetchedTokens);

    return finalTokens;
  }, [userInfo?.tokens, tokenClaimed, tokenPendingClaim]);

  const getItemLeftTime = useCallback((item: ClaimingToken) => {
    const { tokensLockedUntil } = item.undelegateInfo;

    if (!tokensLockedUntil || !(tokensLockedUntil > 0)) {
      return 'Current withdrawal period is 28 day(s).';
    }

    const leftMs = dayjs(tokensLockedUntil * 1000).diff(dayjs(), 'millisecond');
    const result = convertMillisecondsToTime(leftMs);
    return `Thawing Time Left: ${result.days}D ${result.hours}H ${result.minutes}M`;
  }, []);

  return (
    <StyledPanel>
      {/* {!isActive && <AlertTips>Connect wallet to see your withdrawal requests</AlertTips>} */}

      {isActive && (
        <WithdrawStatusCard
          wstBalance={wstgrtValue}
          userInfo={userInfo}
          gstakeStats={gstakeStats}
        />
      )}

      {displayWithdrawRecords.length > 0 ? (
        displayWithdrawRecords.map((item) => (
          <SingleClaimItem
            className={WithdrawStatusString[item.status]}
            key={`claim_${item.id}`}
          >
            {/* eslint-disable-next-line */}
            <div onClick={() => handleCheck(item)} className="left">
              {ids.includes(item.id) ? <CheckedSvg /> : <UncheckedSvg />}{' '}
              {new Decimal(item.amountOfGRT).toFixed(2, Decimal.ROUND_DOWN)} GRT
            </div>
            <div className="right">
              <div className={clsx('claim-status', WithdrawStatusString[item.status])}>
                {_capitalize(WithdrawStatusTitle[item.status])}

                {item.status === WithdrawStatus.CLAIMABLE && (
                  <div style={{ marginLeft: 5 }}>
                    <ClaimSvg />
                  </div>
                )}

                {item.status === WithdrawStatus.PENDING && (
                  <TipsText
                    overlayText={getItemLeftTime(item)}
                    placement="topLeft"
                    triggerNode={
                      <div style={{ marginLeft: 5 }}>
                        <PendingSvg />
                      </div>
                    }
                  ></TipsText>
                )}

                {item.status === WithdrawStatus.PROCESSING && (
                  <TipsText
                    overlayText={`Processing waiting period less than one day.`}
                    placement="topLeft"
                    triggerNode={
                      <div style={{ marginLeft: 5 }}>
                        <ProcessingSvg />
                      </div>
                    }
                  ></TipsText>
                )}
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${getScanUrl(currentChainId)}${GSTAKE_CONTRACT}?a=${+item.id}`}
              >
                <LinkSvg />
              </a>
            </div>
          </SingleClaimItem>
        ))
      ) : (
        <EmptyText>No withdrawal requests detected.</EmptyText>
      )}

      {!isActive ? (
        <LoginButton type={ConnectButtonPositionType.CONTENT} showWalletInfo={false} />
      ) : (
        <ConfirmButton
          disabled={ids.length === 0}
          onClick={() => {
            handleClaim();
          }}
          loading={loading}
          className="claim-button"
          loadingElement={<ButtonLoading />}
        >
          {loading
            ? ''
            : `Claim${+selectedGrtValue > 0 ? ` ${selectedGrtValue} GRT` : ''}`}
        </ConfirmButton>
      )}

      {Object.keys(transModalProps).length > 0 && (
        <TransactionModal
          onCancel={() => setTransModalProps({})}
          visible={true}
          {...transModalProps}
        />
      )}
    </StyledPanel>
  );
});

ClaimPanel.displayName = 'ClaimPanel';

export default ClaimPanel;

const SingleClaimItem = styled.div`
  width: 420px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #2f5656;
  background: #0d191a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
  cursor: not-allowed;

  &.ready {
    cursor: default;

    .left {
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-family: 'Alibaba';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    > svg {
      margin-right: 16px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .claim-status {
      padding: 0 16px;
      height: 26px;
      border-radius: 13px;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      &.ready {
        background: rgba(0, 152, 125, 0.4);
        color: #00dc9a;
      }
      &.processing {
        background: rgba(0, 169, 241, 0.4);
        color: #acd8ff;
      }
      &.pending {
        background: rgba(152, 79, 0, 0.4);
        color: #ff9c06;
      }
    }
  }
`;

const EmptyText = styled.div`
  color: #fff;
  text-align: center;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;
