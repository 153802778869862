import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Claim from './Claim';
import Withdrawal from './Withdrawal';

const WithdrawRoutes = memo(() => {
  useEffect(() => {
    document.title = 'Withdrawals';
  }, []);

  return (
    <BorrowWrapper>
      <Routes>
        <Route path="/" element={<Withdrawal />} />
        <Route path="/claim" element={<Claim />} />
      </Routes>
    </BorrowWrapper>
  );
});

WithdrawRoutes.displayName = 'WithdrawRoutes';

export default WithdrawRoutes;

const BorrowWrapper = styled.div`
  width: 100%;
  min-width: 1000px;
  position: relative;
`;
