import styled from '@emotion/styled';
import tw from 'twin.macro';

import WithdrawNav from './components/WithdrawNav';
import WithdrawPanel from './components/WithdrawPanel';
import WithdrawQuestions from './components/WithdrawQuestions';

const Withdrawal: React.FC = () => {
  return (
    <WithdrawalWrapper>
      <TitleWrap>
        <div className="title">Withdrawals</div>
        <div className="sub-title">Request WstGRT withdrawal and claim GRT</div>
      </TitleWrap>

      <WithdrawNav />

      <WithdrawPanel />

      <WithdrawQuestions />
    </WithdrawalWrapper>
  );
};

export default Withdrawal;

const WithdrawalWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
`;

const TitleWrap = styled.div`
  ${tw`flex flex-col justify-center items-center`}
  margin-bottom: 48px;

  .title {
    color: #fff;
    font-family: 'Alibaba';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-title {
    color: #cacbcd;
    font-family: 'Alibaba';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
