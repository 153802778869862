import styled from '@emotion/styled';

import { StyledPanel } from '@/pages/Main/components/MainPanel';
import { ReactComponent as GrtSvg } from '@/static/img/gstake/icon_grt.svg';
import { useActiveWeb3React } from '@/web3/WalletProvider';

const AssetsRecord: React.FC = () => {
  const { isActive } = useActiveWeb3React();

  if (!isActive) {
    return (
      <RecordPanel height={155}>
        <AssetsLine>
          <div className="single-asset">
            <div className="title">stGRT balance</div>
            <div className="value">
              <GrtSvg /> -
            </div>
            <div className="sub-value">$ -</div>
          </div>
          <div className="single-asset">
            <div className="title">stGRT rewarded</div>
            <div className="value">
              <GrtSvg /> -
            </div>
            <div className="sub-value">$ -</div>
          </div>
          <div className="single-asset">
            <div className="title">Average APR</div>
            <div className="value">-</div>
            <div className="sub-value">More info</div>
          </div>
          <div className="single-asset">
            <div className="title">stGRT Price</div>
            <div className="value">$ -</div>
            <div className="sub-value">
              <GrtSvg /> -
            </div>
          </div>
        </AssetsLine>
      </RecordPanel>
    );
  }

  return (
    <RecordPanel height={155}>
      <AssetsLine>
        <div className="single-asset">
          <div className="title">stGRT balance</div>
          <div className="value">
            <GrtSvg /> -
          </div>
          <div className="sub-value">$ -</div>
        </div>
        <div className="single-asset">
          <div className="title">stGRT rewarded</div>
          <div className="value">
            <GrtSvg /> -
          </div>
          <div className="sub-value">$ -</div>
        </div>
        <div className="single-asset">
          <div className="title">Average APR</div>
          <div className="value">-</div>
          <div className="sub-value">More info</div>
        </div>
        <div className="single-asset">
          <div className="title">stGRT Price</div>
          <div className="value">$ -</div>
          <div className="sub-value">
            <GrtSvg /> -
          </div>
        </div>
      </AssetsLine>
    </RecordPanel>
  );
};

export default AssetsRecord;

export const RecordPanel = styled(StyledPanel)`
  width: 800px;
  margin-bottom: 18px;

  .inner {
    width: 798px;
    padding: 40px;
  }
`;

const AssetsLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .single-asset {
    width: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .title {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      font-family: 'Alibaba';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      > svg {
        margin-right: 4px;
        width: 24px;
        height: 24px;
      }
    }
    .sub-value {
      color: #fff;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > svg {
        width: 16px;
        margin-right: 4px;
        height: 16px;
      }
    }
  }
`;
