import type { FC } from 'react';
import { memo } from 'react';

import ChartViewAdapter from './ChartViewAdapter';

import type { ChartViewProps } from './chartService';

const ChartView: FC<ChartViewProps> = ({ data, onHover, height, isFetching }) => {
  const lineColor = '#2F8E7F';
  const topColor = '#282830';
  const bottomColor = '#282830';

  return (
    <ChartViewAdapter
      isFetching={isFetching}
      height={height}
      data={data}
      lineColor={lineColor}
      topColor={topColor}
      bottomColor={bottomColor}
      onHover={onHover}
    />
  );
};
ChartView.displayName = 'ChartView';
export default memo(ChartView);
