import React, { memo } from 'react';

import { QuestionsWrapper, SingleFaq } from '@/pages/Main/components/Questions';

const WITHDRAW_QUESTIONS_LIST = [
  // {
  //   title: 'What are withdrawals?',
  //   content:
  //     'Users may decollateralize their WstGRT holdings through a withdrawal process. Upon initiating a withdrawal, Gstake will commence the redemption procedure for GRT in the background.',
  // },
  {
    title: 'How does the withdrawal process work?',
    content: (
      <>
        The withdrawal process is simple and has two steps: <br />
        1. <span style={{ fontWeight: 'bold' }}>Request withdrawal</span>: Lock your
        WstGRT by issuing a withdrawal request. GRT is sourced to fulfill the request, and
        then locked WstGRT is burned, which marks the withdrawal request as claimable.
        Under normal circumstances, this can take anywhere between 1-28 days.
        <br />
        2. <span style={{ fontWeight: 'bold' }}>Claim</span>: Claim your GRT after the
        withdrawal request has been processed.
      </>
    ),
  },
  {
    title: 'How long does it take to withdraw?',
    content:
      'Under normal circumstances, the WstGRT withdrawal period can take anywhere between 1-28 days. After that, you can claim your GRT using the Claim tab.',
  },
  {
    title: 'Do I still get rewards after I withdraw?',
    content:
      'No, you will not continue to receive rewards after you withdraw. Once you initiate a withdrawal, the WstGRT that you’ve unstaked will stop accruing staking rewards, meaning no additional rewards will be added to your submitted balance.',
  },
  {
    title: 'Is there a fee for withdrawal?',
    content:
      'There’s no withdrawal fee. However, similar to any Ethereum interaction, a network gas fee will apply. Please note that Gstake does not charge a fee when you request a withdrawal.',
  },
  {
    title: 'What is Gstake withdrawal NFT?',
    content:
      'Each withdrawal request is represented by an NFT: the NFT is automatically minted for you when you send a request. You will need to add it to your wallet to be able to monitor the request status. When the request is ready for the claim, the NFT will change its appearance.',
  },
  {
    title: 'How do I add the Gstake NFT to my wallet?',
    content: (
      <>
        Different wallets have specific functionalities for adding and working with NFTs.
        Most often, you need to find the specific NFT Address and Token ID. You can find
        these parameters on Etherscan. Visit Etherscan, add your wallet, and locate the
        NFT transaction. Once located, open the NFT transaction, and you will see the
        Address and Token ID.
        {/* <br />
        <br />
        If you are a MetaMask user, use{' '}
        <a
          target="_blank"
          style={{ color: '#0a90d8' }}
          rel="noopener noreferrer"
          href="https://help.lido.fi/en/articles/7858367-how-do-i-add-the-lido-nft-to-metamask"
        >
          this guide
        </a>
        . */}
      </>
    ),
  },
  {
    title: 'What is Withdrawal limit?',
    content:
      'Gstake will delegate the user’s GRT to a group of delegators. However, it will only process the request for withdrawing coins through one delegator per day. Therefore, there will be a limit on the amount of coins that can be withdrawn each day. For more details, please refer to the mechanism of withdrawing coins.',
  },
];

const WithdrawQuestions = memo(() => {
  return (
    <QuestionsWrapper>
      <div className="title">FAQ</div>

      {WITHDRAW_QUESTIONS_LIST.map((item, index) => (
        <SingleFaq
          defaultOpen={index === 0}
          key={item.title + index}
          title={item.title}
          content={item.content}
        />
      ))}
    </QuestionsWrapper>
  );
});

WithdrawQuestions.displayName = 'WithdrawQuestions';

export default WithdrawQuestions;
