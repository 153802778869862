import styled from '@emotion/styled';
import Lottie from 'lottie-react';
import React, { memo, useCallback } from 'react';

import { ModalProps } from '@/components/common/CustomModal';
import GstakeModal from '@/components/enhanced/GstakeModal';
import LoadingPng from '@/static/img/gstake/loading.png';
import NftBg from '@/static/img/gstake/nft_bg.png';
import NftContent from '@/static/img/gstake/nft_card.png';
// import LoadingPng from '@/static/img/gstake/loading_circle.gif';
import { ReactComponent as ThumbsSvg } from '@/static/img/gstake/thumbs_up.svg';
import LoadingJson from '@/static/img/loading.json';
import { CHAINS } from '@/web3/constants';
import { useActiveWeb3React } from '@/web3/WalletProvider';

export enum TransactionStatus {
  WAITING_CONFIRM,
  PENDING,
  COMPLETE,
}

export enum OperationType {
  DEPOSIT,
  WITHDRAW,
  CLAIM,
}

export interface TransactionProps {
  type?: OperationType;
  withdrawWstGrt?: number;
  status?: TransactionStatus;
  hash?: string;
  content?: string | JSX.Element;
  subContent?: string | JSX.Element;
  footer?: string | JSX.Element;
  afterClose?: () => void;
}

const TransactionModal = memo(
  ({
    visible,
    onCancel,
    className,
    loading,
    type,
    withdrawWstGrt,
    status,
    hash,
    content,
    subContent,
    footer,
    afterClose,
  }: TransactionProps & ModalProps) => {
    const { currentChainId } = useActiveWeb3React();

    const getScanUrl = useCallback(
      (chainId: number) => {
        const _targetChainId = chainId || currentChainId;
        if (CHAINS?.[_targetChainId] && CHAINS?.[_targetChainId]?.blockExplorerUrls) {
          return CHAINS?.[_targetChainId]?.blockExplorerUrls?.[0];
        }
        return '';
      },
      [currentChainId],
    );

    return (
      <GStakeModalWrap
        visible={visible}
        width={600}
        onCancel={onCancel}
        className={className}
        loading={loading}
        maskClosable={status === TransactionStatus.COMPLETE}
        closable={status === TransactionStatus.COMPLETE}
        afterClose={afterClose}
      >
        <div className="inner">
          {status !== TransactionStatus.COMPLETE && (
            // <div className="loading-wrap">
            //   <img src={LoadingPng} alt="" className="loading" />
            // </div>
            <AnimateWrap>
              <Lottie animationData={LoadingJson} />
            </AnimateWrap>
          )}
          {status === TransactionStatus.COMPLETE && (
            <AnimateWrap className="complete">
              <ThumbsSvg />
            </AnimateWrap>
          )}
          <div className="content-wrap">{content}</div>
          <div className="sub-content-wrap">{subContent}</div>

          {hash ? (
            <div className="footer-wrap">
              {status === TransactionStatus.COMPLETE ? (
                <>
                  Transaction can be{' '}
                  <a
                    className="etherscan-link"
                    target="_blank"
                    rel="noreferrer"
                    href={`${getScanUrl(currentChainId)}${hash}`}
                  >
                    View on Etherscan
                  </a>
                </>
              ) : (
                <a
                  className="etherscan-link"
                  target="_blank"
                  rel="noreferrer"
                  href={`${getScanUrl(currentChainId)}${hash}`}
                >
                  View on Etherscan
                </a>
              )}
            </div>
          ) : (
            <div className="footer-wrap">{footer}</div>
          )}

          {type === OperationType.WITHDRAW && status === TransactionStatus.COMPLETE && (
            <NftCardWrap>
              <div className="text">
                Add NFT to your wallet to monitor the status of your request.
              </div>
              <div className="nft-wrap">
                <img src={NftBg} alt="" className="background" />
                <img src={NftContent} alt="" className="inner-img" />

                <div className="inner-text-wrap">
                  <div className="line1">{withdrawWstGrt}</div>
                  <div className="line2">
                    <span>WstGRT</span>
                    <div>NFT</div>
                  </div>
                </div>
              </div>
            </NftCardWrap>
          )}
        </div>
      </GStakeModalWrap>
    );
  },
);

TransactionModal.displayName = 'TransactionModal';

export default TransactionModal;

const AnimateWrap = styled.div`
  width: 300px;
  margin: 0 auto;

  &.complete {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
  }

  @media (max-width: 769px) {
    width: 83.33vw;
    height: 83.33vw;
  }
`;

const GStakeModalWrap = styled(GstakeModal)`
  width: 600px !important;
  border-radius: 16px !important;
  min-height: 324px;
  padding-top: 30px;
  background: #26262d !important;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loading-wrap {
      width: 80px;
      height: 80px;
      margin-bottom: 32px;
      /* overflow: hidden;

      > img.loading {
        width: 280px;
        max-width: 280px;
        height: 210px;
        left: 0;
        top: -120px;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        mix-blend-mode: lighten;
      } */
    }

    > svg {
      margin-bottom: 32px;
    }

    .content-wrap {
      color: #42bb81;
      text-align: center;
      font-family: 'Alibaba';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
    .sub-content-wrap {
      color: #999;
      text-align: center;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .footer-wrap {
      color: #456c99;
      text-align: center;
      font-family: 'Alibaba';
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-top: 40px;

      a {
        color: #118eff;
      }
    }
  }
`;

const NftCardWrap = styled.div`
  width: 320px;
  height: 214px;
  border-radius: 8px;
  background: #141414;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .text {
    color: #8d95aa;
    font-family: 'Alibaba PuHuiTi 2.0';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 117px;
    text-align: left;
  }

  .nft-wrap {
    width: 138px;
    height: 176px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .inner-img {
      border-radius: 16px;
      width: 136px;
      height: 174px;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      box-shadow: 0 0 5px 3px rgba(89, 191, 172, 1);
    }

    .inner-text-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 10px 12px;
      z-index: 2;

      .line1 {
        font-family: 'Space Grotesk';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: linear-gradient(97deg, #fff 8.85%, rgba(255, 255, 255, 0.24) 97.33%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: left;
      }

      .line2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        > span {
          text-align: right;
          font-variant-numeric: slashed-zero;
          font-family: 'Space Grotesk';
          font-size: 6.462px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          background: linear-gradient(
            97deg,
            rgba(255, 255, 255, 0.62) 8.85%,
            rgba(255, 255, 255, 0.61) 97.33%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        > div {
          text-align: right;
          font-variant-numeric: slashed-zero;
          font-family: 'Space Grotesk';
          font-size: 6.462px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          background: linear-gradient(
            97deg,
            rgba(255, 255, 255, 0.31) 8.85%,
            #fff 97.33%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
`;
