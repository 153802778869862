import { GRAPH_URL } from '@/hooks/useCjcNftAddr';
import axios from 'axios';
import Decimal from 'decimal.js';

const APIURL =
  'https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-arbitrum';

const GstakeAPIURL = GRAPH_URL;

const oldIndexer = '0x01e110178f15aeec1cccc507939109175dc9c121';
const getApr = async () => {
  try {
    const gstakeInfo = await getGstakeInfo();
    const indexersInfos = gstakeInfo.delegationInfos;
    const totalGRT = gstakeInfo.gstakeInfo.totalGRT;

    const indexers = new Set();
    const delegators = new Set();
    for (let i = 0; i < indexersInfos.length; i++) {
      const indexr = indexersInfos[i];
      indexers.add(indexr.indexerInfo.id);
      delegators.add(indexr.delegator.id);
    }
    indexers.delete(oldIndexer);

    const data = await getIndexersInfo(
      Array.from(indexers.keys()),
      Array.from(delegators.keys()),
      oldIndexer,
    );

    const _delegations = data.delegatedStakes;
    const sharesMap = new Map();
    _delegations.map((__delegations: any) => {
      const indexer = __delegations.indexer.id;
      const shareAmount = BigInt(__delegations.shareAmount);
      if (sharesMap.has(indexer)) {
        sharesMap.set(indexer, sharesMap.get(indexer) + shareAmount);
      } else {
        sharesMap.set(indexer, shareAmount);
      }
    });

    const allIndexers = data._0_NETWORK__indexers;
    const networkInfo = data._1_NETWORK__graphNetwork;
    const totalSignal = BigInt(networkInfo.totalTokensSignalled);
    const yearToken = BigInt(networkInfo.networkGRTIssuancePerBlock) * 365n * 7200n; // total reward per year

    let totalForGstake = BigInt(0);
    allIndexers.map((indexer: any) => {
      if (!sharesMap.has(indexer.id)) {
        return;
      }
      const allocations = indexer.allocations;
      let curYear = BigInt('0');
      //clac per reward of per allocation
      for (let i = 0; i < allocations.length; i++) {
        const allocation = allocations[i];
        const year =
          (yearToken *
            BigInt(allocation.allocatedTokens) *
            BigInt(allocation.subgraphDeployment.signalledTokens)) /
          (totalSignal * BigInt(allocation.subgraphDeployment.stakedTokens));
        curYear = curYear + year;
      }

      const forGstake =
        (curYear *
          (BigInt(1000000) - BigInt(indexer.indexingRewardCut)) *
          sharesMap.get(indexer.id)) /
        (BigInt(indexer.delegatorShares) * BigInt(1000000));
      totalForGstake = totalForGstake + forGstake;
    });
    //clac apr
    const apr = Number(
      BigInt(totalForGstake) /
        BigInt(Math.floor(Number(totalGRT))) /
        BigInt('10000000000000'),
    );
    // console.log(apr);
    return new Decimal(apr / 1000).toFixed(2);
  } catch (error) {
    return '-';
  }
};

/**
 *
 * @returns get delegated indexer info
 */
async function getGstakeInfo() {
  const response = await axios.post(GstakeAPIURL, {
    query: `{
        delegationInfos(first:100){
          id
          delegator{
            id
          }
          indexerInfo{
            id
          grtAmt
          shares
          poolShares
          poolGrtAmt
          }
        }
        gstakeInfo(id:"0x01"){
          id
          totalGRT
        }
      }
      `,
  });
  return response.data.data;
}

/**
 *
 * @returns  get Indexers list
 */
async function getIndexersInfo(indexerIds: any[], delegators: any[], noIndexer: any) {
  const body = {
    query: `query IndexersPage_data($_0__v4_where: Indexer_filter, $_1__v0_id: ID!, $_1__v1_subgraphError: _SubgraphErrorPolicy_!) {
        _0___typename: __typename
        _0_NETWORK__indexers: indexers(where: $_0__v4_where) {
          ..._0_IndexersTable__Indexer
          __typename
        }
        _1___typename: __typename
        _1_NETWORK__graphNetwork: graphNetwork(
          id: $_1__v0_id
          subgraphError: $_1__v1_subgraphError
        ) {
          ..._1_IndexersPage__GraphNetwork
          __typename
        }
        delegatedStakes(where: {delegator_in: ${JSON.stringify(
          delegators,
        )},indexer_not:"${noIndexer}"}) {
          indexer {
            id
          }
          delegator{
            id
          }
          shareAmount
        }
      }
      
      fragment _0_IndexersTable__Indexer on Indexer {
        id
        createdAt
        stakedTokens
        lockedTokens
        allocatedTokens
        delegatedTokens
        indexingRewardCut
        delegatorShares
        queryFeeCut
        indexingRewardEffectiveCut
        queryFeeRebates
        queryFeesCollected
        delegatorQueryFees
        rewardsEarned
        lastDelegationParameterUpdate
        delegationExchangeRate
        transferredToL2
        allocations{
          id
          allocatedTokens
          subgraphDeployment {
            id
            stakedTokens
            signalledTokens
          }
        }
        account {
          id
          metadata {
            image
            __typename
          }
          defaultName {
            id
            name
            __typename
          }
          __typename
        }
        allocationCount
        __typename
      }
      fragment _1_IndexersPage__GraphNetwork on GraphNetwork {
        graphToken
        totalTokensSignalled
        networkGRTIssuancePerBlock
        totalTokensAllocated
      }`,
    variables: {
      _0__v4_where: {
        id_in: indexerIds,
      },
      _1__v0_id: '1',
      _1__v1_subgraphError: 'deny',
    },
  };
  const response = await axios.post(APIURL, body);
  return response.data.data;
}

export { getApr };
