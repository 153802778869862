import axios, { AxiosResponse } from 'axios';
import { gql } from 'graphql-request';

import { CURRENT_GRAPH_ENDPOINT } from '../useCjcNftAddr';
import { useGet, useGraphRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';

// const envPrefix = process.env.REACT_APP_PREFIX || ''

export interface GstakeUserInfo {
  id: string;
  wstGRT: string;
  tokens: any[];
  wstGRTHistory: GstakeHistory[];
}

interface GstakeHistory {
  id: string;
  type: string; // #类型,分四种1.in 2.out 3.deposit 4.withdraw
  wstGRTUsdPrice: string; // #wstGRT 的美元价格
  balance: string; // # 操作完后wstGRT数量
  wstGRT: string; // # 操作wstGRT数量
  blockTimestamp: string; // #时间
  transactionHash: string; // #交易hash
  undelegateInfo: {
    tokensLockedUntil: number; // last withdraw time
  };
}

export interface GstakeStatsInfo {
  id: string;
  apr: string;
  pendingGRT: string;
  totalGRT: string;
  exchangeRate: string;
  taxRate: string;
  feeRate: string;
  uniswapv3Price: string;
  undelegateFreezeTime: string;
  matchTime: string;
  withdrawLeft: string;
  depositLeft: string;
  withdrawMinLimit: string;
}

export const useGstakeUserInfo = (id: string, key: any, queryConfig = {}) => {
  return useGraphRequest<GstakeUserInfo>(
    ['userInfo', id, key],
    id
      ? gql`
            query {
                userInfo(id: "${id.toLowerCase()}") {
                  id
                  wstGRT
                  tokens {
                    id,
                    status,
                    wstGRT,
                    amountOfGRT,
                    transactionHash
                    undelegateInfo{
                      tokensLockedUntil
                    }
                  }
                }
            }
        `
      : null,
    { ...queryConfig, initialData: {} },
  );
};

export const getHistoryInfo = async (id: string, page: number, size: number) => {
  const skip = page * size;

  const response = await axios.post(CURRENT_GRAPH_ENDPOINT, {
    query: `{
        userInfo(id: "${id.toLowerCase()}") {
          id
          wstGRTHistory(skip: ${skip}, first:${size}, orderBy:blockNumber,orderDirection:desc){
            id
            type
            wstGRTUsdPrice
            balance
            wstGRT
            blockTimestamp
            transactionHash 
          }
        }
    }
`,
  });
  const data = response?.data?.data?.userInfo as any;

  return data;
};

export const useGstakeStats = (key: any, queryConfig = {}) => {
  return useGraphRequest<GstakeStatsInfo>(
    ['gstakeInfo', key],
    gql`
      query {
        gstakeInfo(id: "0x01") {
          id
          apr
          pendingGRT
          totalGRT
          exchangeRate
          taxRate
          feeRate
          uniswapv3Price
          undelegateFreezeTime
          matchTime
          withdrawLeft
          depositLeft
          withdrawMinLimit
        }
      }
    `,
    { ...queryConfig, initialData: {} },
  );
};

export const useGrtHistory = (key: any, queryConfig = {}) => {
  return useGraphRequest<any[]>(
    ['wstGrtHistories', key],
    gql`
      query {
        wstGrtHistories(first: 300, orderBy: id, orderDirection: asc) {
          id
          timestamp
          grtRate
          usdRate
        }
      }
    `,
    { ...queryConfig, initialData: [] },
  );
};

export enum IntervalTypes {
  '1D' = '1d',
  '3D' = '3d',
  '7D' = '1w',
  '1M' = '1M',
}

export const useGrtUsdHistory = (
  key: any,
  params: { interval: IntervalTypes },
  options: any,
) => {
  const { interval = IntervalTypes['1D'] } = params;
  const {
    data: response,
    error,
    ...queryParams
  } = useQuery<AxiosResponse<any>>({
    queryKey: key,
    queryFn: () =>
      useGet(
        `https://fapi.binance.com/fapi/v1/klines?symbol=GRTUSDT&interval=${interval}${
          [IntervalTypes['1D'], IntervalTypes['3D']].includes(interval)
            ? `&startTime=1704038400000`
            : ''
        }`,
      ),
    ...options,
  });

  return {
    data: response?.status === 200 && response?.data ? response.data : [] ? [] : false,
    error,
    ...queryParams,
  };
};

export type BinanceData = [
  number,
  string,
  string,
  string,
  string,
  string,
  number,
  string,
  number,
  string,
  string,
];

export const formatBinanceData = (data: BinanceData) => {
  try {
    const [
      _startTime,
      _startPrice,
      _highPrice,
      _lowPrice,
      endPrice,
      _volume,
      endTime,
      _dealAmount,
      _dealOrders,
      _makeIncomeAmount,
      _makeIncomeValue,
    ] = data;

    return { value: endPrice, time: endTime };
  } catch (error) {
    return {};
  }
};
