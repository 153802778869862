import 'twin.macro';

import styled from '@emotion/styled';
import { watchAccount, switchChain } from '@wagmi/core';
import clsx from 'clsx';
import qs from 'query-string';
import Dropdown from 'rc-dropdown';
import Menu from 'rc-menu';
import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';

import useAlert from '@/hooks/useAlert';
import { DEFAULT_CHAIN_ID } from '@/hooks/useCjcNftAddr';
import FaucetModal from '@/pages/Index/components/FaucetModal';
import ProjectsModal, { ProjectItems } from '@/pages/Index/components/ProjectsModal';
import { ReactComponent as HeaderArrowSvg } from '@/static/img/gstake/header_arrow.svg';
import { ReactComponent as FaucetSvg } from '@/static/img/gstake/faucet.svg';
import { ReactComponent as ArbSvg } from '@/static/img/gstake/icon_arb.svg';
import { ReactComponent as DashboardSvg } from '@/static/img/gstake/icon_dashboard.svg';
import { ReactComponent as StakeSvg } from '@/static/img/gstake/icon_stake.svg';
import { ReactComponent as WithdrawSvg } from '@/static/img/gstake/icon_withdraw.svg';
import LogoPng from '@/static/img/gstake/app_beta_icon.png';
import {
  updateSwitchPending,
  updateWalletStatus,
  updateWalletType,
} from '@/store/globalConfig';
import { updateUserInfo } from '@/store/user';
import { wait } from '@/utils/retry';
import { getCurrentChainId, isMainnet } from '@/web3/constants';

import LoginButton, { ConnectButtonPositionType } from '../enhanced/ConnectButton';
import { config } from '@/main';

export type PageType = 'stake' | 'withdraw' | 'dashboard';
const MenuItems = [
  {
    key: 'stake',
    name: 'STAKE',
    path: '/',
    icon: <StakeSvg />,
  },
  {
    key: 'withdraw',
    name: 'WITHDRAWALS',
    path: '/withdrawal',
    icon: <WithdrawSvg />,
  },
  {
    key: 'dashboard',
    name: 'DASHBOARD',
    path: '/dashboard',
    icon: <DashboardSvg />,
  },
];

const Header = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [curPath, setCurPath] = useState<PageType>('stake');
  const { setAlert } = useAlert();
  const currentEnvChainId = getCurrentChainId();
  const { disconnect } = useDisconnect();
  const [faucetVisible, setFaucetVisible] = useState(false);
  const curProject = ProjectItems[0];
  const [projectsVisible, setProjectsVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // const { data: cjcBalance } = useGetBalance(
  //   { params: { owner: account }, key: pendingKey },
  //   {
  //     enabled: isAuthenticated,
  //     initialData: { totalCjc: 0 },
  //   },
  // );
  // useEffect(() => {
  //   updateTokenSubscriptions([
  //     {
  //       isSolid: true,
  //       symbol: 'CLK',
  //       address: CLINK_ADDRESS,
  //       decimals: 18,
  //     },
  //     {
  //       isSolid: true,
  //       symbol: GstakeTokenList['CJC'].symbol,
  //       address: GstakeTokenList['CJC'].tokenAddress,
  //       decimals: 18,
  //     },
  //     {
  //       isSolid: true,
  //       symbol: GstakeTokenList['USDT'].symbol,
  //       address: GstakeTokenList['USDT'].tokenAddress,
  //       decimals: 18,
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('home') || pathname === '/') {
      setCurPath('stake');
    } else if (pathname.includes('dashboard')) {
      setCurPath('dashboard');
    } else if (pathname.includes('withdraw')) {
      setCurPath('withdraw');
    }
  }, [location]);

  const clearConnectAction = () => {
    disconnect();
    dispatch(updateWalletStatus(false));
    dispatch(updateWalletType(null));
    dispatch(updateUserInfo(null));
    localStorage.removeItem('ethConnectType');
  };

  const {
    address: accountAddress,
    isConnected: isActive,
    isConnecting: isActivating,
  } = useAccount();

  const networkMenu = (changeNetworkFunc: (v: number) => void) => {
    return (
      <NetworkMenu style={{ width: 200 }}>
        <NetworkOption
          className="arb"
          onClick={() => changeNetworkFunc(+DEFAULT_CHAIN_ID)}
        >
          <ArbSvg />
          {isMainnet ? 'Arbitrum' : 'Arbitrum Sepolia'}
        </NetworkOption>
        {/* <NetworkOption
          onClick={() => {
            setAlert({ message: 'Coming soon!', type: 'notice' });
          }}
        >
          <EthSvg />
          Sepolia
        </NetworkOption> */}
      </NetworkMenu>
    );
  };

  const [switchChainPending, setSwitchChainPending] = useState(false);

  const handleVisit = async (networkId: number) => {
    const curDefaultChainId = getCurrentChainId();
    if (networkId === +curDefaultChainId) {
      setDropdownVisible(false);
      return;
    }

    try {
      dispatch(updateSwitchPending(true));
      const result = await switchChain?.(config, { chainId: networkId });

      if (result) {
        localStorage.setItem('gstakeChainId', networkId.toString());
        await wait(1000);
        setDropdownVisible(false);
        const url = `/?${qs.stringify({ targetChain: networkId })}`;
        window.location.href = url;
      }
    } catch (error) {
      console.log(error, 'switch chain failed');
      dispatch(updateSwitchPending(false));
    } finally {
      await wait(100);
      dispatch(updateSwitchPending(false));
    }
  };

  useEffect(() => {
    const unwatch = watchAccount(config, {
      onChange(data) {
        const chains = config.chains;
        const chain = chains.find((chain) => chain.id === data.chainId);

        const curId = getCurrentChainId();
        if (
          chain?.id &&
          chains.some((x) => +x.id === +chain?.id && +chain.id !== +curId)
        ) {
          localStorage.setItem('gstakeChainId', chain.id + '');

          setTimeout(() => {
            alert(4);
            const url = `/?${qs.stringify({ targetChain: chain.id })}`;
            window.location.href = url;
          }, 300);
        }
      },
    });

    return () => {
      unwatch();
    };
  }, [watchAccount, currentEnvChainId]);

  return (
    <HeaderWrapper height={70}>
      <ContentWrapper>
        <div className="left">
          <Link to="/">
            <div className={clsx('logo-wrap')}>
              <img src={LogoPng} alt="" />
            </div>
          </Link>

          <ProjectSwitcher
            onClick={() => {
              setProjectsVisible(true);
            }}
          >
            <div className="inner">
              {curProject.icon}
              {curProject.name}
            </div>
            <HeaderArrowSvg />
          </ProjectSwitcher>
        </div>

        <MenuList>
          {MenuItems.map((item) => (
            <Link to={item.path} key={item.key}>
              <SingleMenu className={item.key} isActive={curPath === item.key}>
                {item.icon}
                {item.name}
              </SingleMenu>
            </Link>
          ))}
        </MenuList>

        <div style={{ gap: 12 }} className="right">
          {isActive && !isMainnet && (
            <FaucetButton onClick={() => setFaucetVisible(true)}>
              <FaucetSvg /> faucet
            </FaucetButton>
          )}
          {isActive && (
            <Dropdown
              overlay={networkMenu(handleVisit)}
              animation="slide-up"
              placement="bottomLeft"
              visible={dropdownVisible}
              onVisibleChange={(v) => setDropdownVisible(v)}
              overlayClassName="dropdownMenu"
            >
              <NetworkSwitcher>
                <div className="dot"></div>
                {isMainnet ? 'Arbitrum' : 'Arbitrum Sepolia'}
              </NetworkSwitcher>
            </Dropdown>
          )}
          <LoginButton type={ConnectButtonPositionType.HEADER} showWalletInfo={true} />
        </div>
      </ContentWrapper>

      {faucetVisible && (
        <FaucetModal visible={faucetVisible} onCancel={() => setFaucetVisible(false)} />
      )}
      {projectsVisible && (
        <ProjectsModal
          visible={projectsVisible}
          onCancel={() => setProjectsVisible(false)}
        />
      )}
    </HeaderWrapper>
  );
});

Header.displayName = 'Header';

export default Header;

const FaucetButton = styled.div`
  display: flex;
  height: 48px;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  border: 1px solid rgba(59, 53, 82, 0.4);
  background: rgba(35, 31, 54, 0.4);
  margin-right: 8px;
  cursor: pointer;

  > svg {
    margin-right: 6px;
  }
`;

const HeaderWrapper = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  min-width: 1000px;
  flex-shrink: 0;
  /* border-right: 1px solid #1e2127; */
  z-index: 4;
  padding: 0 40px;
  position: relative;
  /* background: #0d0c13; */
  top: 0;
  padding-top: 20px;

  @media (max-width: 1200px) {
    height: 150px;
  }

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  transition: all 0.5s;

  .logo {
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 45px;
    color: #000;
    margin-bottom: 40px;
  }
  .divide-line {
    margin: 24px auto;
    height: 1px;
    background: #868b90;
    opacity: 0.2;
    width: 100%;
    flex-shrink: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .logo-wrap {
    width: 148px;
    height: 45.4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &.wallet {
    padding-top: 20px;
    padding-bottom: 0;
  }
`;

const MenuList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  margin: auto;
  width: 600px;

  @media (max-width: 1600px) {
    top: 80px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }
`;

const NetworkSwitcher = styled.div`
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 1px solid rgba(59, 53, 82, 0.4);
  background: rgba(35, 31, 54, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Alibaba';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #25b774;
    margin-right: 6px;
  }
`;

const ProjectSwitcher = styled.div`
  width: 168px;
  height: 40px;
  background: linear-gradient(90deg, #6dbbba 0%, #e7c178 50%, #c570d9 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  position: relative;
  cursor: pointer;

  > svg {
    position: absolute;
    right: 16px;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  .inner {
    width: 166px;
    height: 38px;
    border-radius: 38px;
    background: #282830;
    padding: 0 6px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;

    > svg {
      margin-right: 10px;
    }
  }
`;

const NetworkOption = styled.div`
  color: #fff;
  font-family: 'Alibaba';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    margin-right: 8px;
    width: 24px;
  }

  &.arb {
    > svg {
      width: 24px;
      transform: scale(1.05);
    }
  }

  &:hover {
    background: #2e264a;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const NetworkMenu = styled(Menu)`
  width: 200px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px;

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 8px;
    margin-left: 6px;
    margin-top: 6px;
    &:hover {
      color: #ffffff !important;
    }
  }
`;

const SingleMenu = styled.div<{ isActive?: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.isActive ? '#fff' : '#4F4F4F')};
  margin-right: 48px;
  font-family: 'Alibaba';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.dashboard {
    margin-right: 0;
  }

  position: relative;
  cursor: pointer;

  > svg {
    margin-right: 8px;

    > path {
      stroke: ${(props) =>
        props.isActive ? 'url(#paint0_linear_2442_4517)' : '#A1A1A3'};
    }
  }

  &:hover {
    color: ${(props) => (props.isActive ? '#fff' : '#fff')};
    > svg > path {
      stroke: ${(props) =>
        props.isActive
          ? 'url(#paint0_linear_2442_4517)'
          : 'url(#paint0_linear_2442_4517)'};
    }
  }
`;
