import styled from '@emotion/styled';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import WalletPng from '@/static/img/gstake/connect_button.png';

const WithdrawNav = memo((props: any) => {
  const curNav = window.location.pathname.indexOf('claim') !== -1 ? 'claim' : 'withdraw';

  return (
    <NavWrapper>
      <SingleButton
        className={clsx(curNav === 'withdraw' && 'isActive')}
        to="/withdrawal"
      >
        Request
        <div className="button-bg">
          <img src={WalletPng} alt="" />
        </div>
      </SingleButton>
      <SingleButton
        className={clsx(curNav === 'claim' && 'isActive')}
        to="/withdrawal/claim"
      >
        Claim
        <div className="button-bg">
          <img src={WalletPng} alt="" />
        </div>
      </SingleButton>
    </NavWrapper>
  );
});

WithdrawNav.displayName = 'WithdrawNav';

const NavWrapper = styled.div`
  width: 420px;
  height: 56px;
  border-radius: 16px;
  background: #282830;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const SingleButton = styled(Link)`
  width: 210px;
  height: 56px;
  color: #444;
  text-align: center;
  font-family: 'Alibaba';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .button-bg {
    width: 210px;
    height: 56px;
    position: absolute;
    left: 0;
    top: 0;
    display: none;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  &.isActive {
    color: #fff;

    .button-bg {
      display: block;
    }
  }
`;

export default WithdrawNav;
