import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface ButtonLoadingProps {
  justifyContent?: string;
  size?: number;
  color?: string;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = ({
  size = 30,
  color = '#fff',
  justifyContent = 'center',
}) => {
  return (
    <ButtonLoadingWrapper size={size} color={color} justifyContent={justifyContent}>
      <div className="button-loading"></div>
    </ButtonLoadingWrapper>
  );
};

export default ButtonLoading;

export const spin = () => keyframes`
  to {
    transform: rotate(360deg); 
  } 
`;

const ButtonLoadingWrapper = styled.div<ButtonLoadingProps>`
  .button-loading {
    width: ${(props) => props.size + 'px'};
    height: ${(props) => props.size + 'px'};
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent};
    color: #fff;

    &:after {
      border: 4px solid ${(props) => props.color};
      border-radius: 100%;
      content: '';
    }

    &:after {
      animation: ${() => spin()} 1.5s infinite linear;
      border-right-color: transparent;
      height: ${(props) => props.size + 'px'};
      width: ${(props) => props.size + 'px'};
    }
  }
`;
