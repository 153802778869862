import { useEffect, useMemo, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import useInterval from '@/hooks/useInterval';
import { useBlock, useBlockNumber, useReadContracts } from 'wagmi';
import { GstakeTokenList } from './useCjcNftAddr';
import { erc20Abi } from 'viem';
import { useActiveWeb3React } from '@/web3/WalletProvider';
import { useSelector } from 'react-redux';
import { selectPendingEndResult } from '@/store/globalConfig';

const useBalance = () => {
  const { isActive, account, web3Provider } = useActiveWeb3React();

  const { data: blockNumber } = useBlockNumber({ watch: true });

  const { data, refetch: refetchGrt } = useReadContracts({
    contracts: [
      {
        address: GstakeTokenList.GRT.tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [account as `0x${string}`],
      },
    ],
  });

  const { data: wstGRTdata, refetch: refetchWstGrt } = useReadContracts({
    contracts: [
      {
        address: GstakeTokenList.wstGRT.tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [account as `0x${string}`],
      },
    ],
  });

  useEffect(() => {
    refetchGrt();
    refetchWstGrt();
  }, [blockNumber]);

  const grtValue = useMemo(() => {
    if (data?.length && data[0].status === 'success' && data[0].result) {
      return new BigNumber(data[0].result.toString()).div(`1e${18}`).toString();
    }
    return '0';
  }, [data]);

  const wstgrtValue = useMemo(() => {
    if (
      wstGRTdata?.length &&
      wstGRTdata[0].status === 'success' &&
      wstGRTdata[0].result
    ) {
      return new BigNumber(wstGRTdata[0].result.toString()).div(`1e${18}`).toString();
    }
    return '0';
  }, [wstGRTdata]);

  return {
    grtValue,
    wstgrtValue,
  };
};

export default useBalance;
