import Decimal from 'decimal.js';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WalletIconPng } from '@/components/enhanced/ConnectButton';
import { GstakeStatsInfo, GstakeUserInfo } from '@/hooks/queries/useGstakeGraph';
import {
  AprBlock,
  CardWrapper,
  StakeAvailable,
  StakedAmount,
  WalletInfoWrap,
} from '@/pages/Main/components/StatusCard';
import { ReactComponent as PendingSvg } from '@/static/img/gstake/icon_pending.svg';
import { ReactComponent as ReadySvg } from '@/static/img/gstake/icon_ready.svg';
import { updateAccountModalVisible } from '@/store/globalConfig';
import { getPrecisionedAmount } from '@/utils/format';
import { formatAddress } from '@/web3/format';
import { useActiveWeb3React } from '@/web3/WalletProvider';

export enum WithdrawStatus {
  PROCESSING = '1',
  PENDING = '2',
  CLAIMABLE = '3',
  COMPLETED = '4',
}

export const WithdrawStatusString: Record<any, string> = {
  [WithdrawStatus.PENDING]: 'pending',
  [WithdrawStatus.PROCESSING]: 'processing',
  [WithdrawStatus.CLAIMABLE]: 'ready',
};

export const WithdrawStatusTitle: Record<any, string> = {
  [WithdrawStatus.PENDING]: 'pending',
  [WithdrawStatus.PROCESSING]: 'processing',
  [WithdrawStatus.CLAIMABLE]: 'ready to claim',
};

const WithdrawStatusCard = memo(
  ({
    userInfo,
    gstakeStats,
    wstBalance,
  }: {
    userInfo?: GstakeUserInfo;
    gstakeStats?: GstakeStatsInfo;
    wstBalance: string;
  }) => {
    const dispatch = useDispatch();

    const showAccountModal = () => {
      dispatch(updateAccountModalVisible(true));
    };

    const [curWallet, setCurWallet] = useState('');

    const { isActive, account } = useActiveWeb3React();

    useEffect(() => {
      if (isActive) {
        const walletType = localStorage.getItem('wagmi.wallet') || '';
        setCurWallet(walletType.replaceAll('"', ''));
      }
    }, [isActive]);

    const requestAmount = useMemo(() => {
      const { tokens = [] } = userInfo || {};

      const pendingAmount = tokens.filter(
        (x) =>
          x.status === WithdrawStatus.PENDING || x.status === WithdrawStatus.PROCESSING,
      ).length;

      const claimableAmount = tokens.filter(
        (x) => x.status === WithdrawStatus.CLAIMABLE,
      ).length;

      return {
        pendingAmount,
        claimableAmount,
      };
    }, [userInfo]);

    const pendingWstGrt = useMemo(() => {
      const { tokens = [] } = userInfo || {};

      const pendingAmount = tokens.filter(
        (x) =>
          x.status === WithdrawStatus.PENDING || x.status === WithdrawStatus.PROCESSING,
      );

      if (!pendingAmount.length) {
        return 0;
      }

      return pendingAmount.reduce((s, x) => s + +x.wstGRT, 0);
    }, [userInfo]);

    const pendingGrt = useMemo(() => {
      const { tokens = [] } = userInfo || {};

      const pendingAmount = tokens.filter(
        (x) =>
          x.status === WithdrawStatus.PENDING || x.status === WithdrawStatus.PROCESSING,
      );

      if (!pendingAmount.length) {
        return 0;
      }

      const _total = pendingAmount.reduce(
        (s, x) => s.plus(x.amountOfGRT),
        new Decimal(0),
      );

      return _total.toFixed(2, Decimal.ROUND_DOWN);
    }, [userInfo]);

    return (
      <CardWrapper>
        <div className="line1">
          <AprBlock className="withdraw">
            <div className="content">
              <div className="label">My request</div>
              <div className="value withdraw">
                <ReadySvg /> {requestAmount.claimableAmount}
                <PendingSvg /> {requestAmount.pendingAmount}
              </div>
            </div>
          </AprBlock>

          <WalletInfoWrap onClick={showAccountModal}>
            <div className="wallet-addr">{formatAddress(account)}</div>

            {WalletIconPng?.[curWallet]}
          </WalletInfoWrap>
        </div>

        <div className="line2" style={{ height: 82, marginTop: 20 }}>
          <StakeAvailable>
            <div className="label">WstGRT Balance</div>
            <div className="value">
              {wstBalance ? getPrecisionedAmount(wstBalance, 'wstGRT') : '0.00'} WstGRT
            </div>
          </StakeAvailable>

          {!!pendingWstGrt && (
            <StakedAmount>
              <div className="label">My pending amount</div>
              <div className="value">{pendingGrt} GRT </div>
            </StakedAmount>
          )}
        </div>
      </CardWrapper>
    );
  },
);

WithdrawStatusCard.displayName = 'WithdrawStatusCard';

export default WithdrawStatusCard;
