import styled from '@emotion/styled';
import Lottie from 'lottie-react';

import LoadingJson from '@/static/img/loading.json';

interface LoadingProps {
  size: number;
}

const LoadingMask: React.FC<LoadingProps> = ({ size = 300 }) => {
  return (
    <MaskWrapper>
      <AnimateWrap size={size}>
        <Lottie animationData={LoadingJson} />
      </AnimateWrap>
    </MaskWrapper>
  );
};

export default LoadingMask;

const MaskWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  background: rgba(40, 40, 48, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnimateWrap = styled.div<{ size: number }>`
  width: ${(props) => props.size + 'px'};
  margin: 0 auto;
`;
