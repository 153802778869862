import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Lockr from 'lockr';
import _isEqual from 'lodash/isEqual';
import { memo, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';

import Header from '@/components/common/Header';
import AppLoading from '@/components/enhanced/AppLoading';
import { UserData, useUserInfo } from '@/hooks/queries/useAuthApis';
import useAlert from '@/hooks/useAlert';
import BgElementImg from '@/static/img/gstake/bg_element.jpeg';
import BgImg from '@/static/img/gstake/main_bg.jpeg';
// import SideBar from 'components/common/SideBar';
import {
  selectAccountModalVisible,
  selectPendingEndResult,
  selectScrollTopTrigger,
  updateAccountModalVisible,
  updateWalletStatus,
  updateWalletType,
} from '@/store/globalConfig';
// import AccountDrawer from 'components/enhanced/AccountDrawer/AccountDrawer';
// import Pooling from 'components/enhanced/BlockNumberPoolling';
// import { useActiveWeb3React } from '@/web3/WalletProvider';
import { selectUserInfo, updateUserInfo } from '@/store/user';
import { isSameAddress } from '@/utils/format';
import { storage } from '@/utils/storage';

import AccountModal from './components/AccountModal';

const Index = memo(() => {
  const { address: account } = useAccount();
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const userInfo = useSelector(selectUserInfo);
  const pendingEndResult = useSelector(selectPendingEndResult);
  const accountModalVisible = useSelector(selectAccountModalVisible);
  // const { data: fetchedUserInfo } = useUserInfo(
  //   {
  //     params: { id: userInfo?.id || 0 },
  //     key: `${pendingEndResult}_${userInfo?.id || ''}`,
  //   },
  //   { enabled: Boolean(userInfo?.id) },
  // );

  // useEffect(() => {
  //   if (
  //     fetchedUserInfo?.id &&
  //     _isEqual(fetchedUserInfo.id, userInfo?.id) &&
  //     !_isEqual(fetchedUserInfo, userInfo)
  //   ) {
  //     dispatch(updateUserInfo(fetchedUserInfo));
  //   }
  // }, [fetchedUserInfo]);

  const checkExistedToken = (address: string) => {
    const userTokenMap: any = storage.getLocalStorageObject('userTokenMap', null);
    const userData = userTokenMap?.[address];
    if (userData && !dayjs().isAfter(dayjs(userData.expireTime))) {
      return userData.user;
    }
    return false;
  };

  useEffect(() => {
    if (!userInfo?.address || !account) {
      return;
    }
    if (!isSameAddress(account, userInfo.address)) {
      const existedUser = checkExistedToken(account);

      if (existedUser) {
        dispatch(updateUserInfo(existedUser));
        setAlert({ type: 'notice', message: 'Switching Account...' });
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } else {
        dispatch(updateWalletStatus(false));
        dispatch(updateWalletType(null));
        dispatch(updateUserInfo(null));
        // Lockr.rm('ethConnectType');
        // Lockr.rm('redux_localstorage_simple_user');
        localStorage.removeItem('ethConnectType');
        localStorage.removeItem('stored_userInfo');
        localStorage.removeItem('redux_localstorage_simple_user');
        disconnect();
        setTimeout(() => {
          window.location.href = '/';
        }, 500);
      }
    }
  }, [account]);

  const scrollRef = useRef<any>(null);
  const scrollToTopTrigger = useSelector(selectScrollTopTrigger);

  useEffect(() => {
    if (scrollRef.current?.view) {
      scrollRef.current.view.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollToTopTrigger]);

  const handleCancel = () => {
    dispatch(updateAccountModalVisible(false));
  };

  return (
    <IndexWrapper>
      <AppLoading />
      {/* <SideBar /> */}
      <ContentWrapper
        ref={scrollRef}
        renderThumbHorizontal={({ style, ...props }: { style: any }) => (
          <div
            {...props}
            style={{
              ...style,
              backgroundColor: '#43464b',
              borderRadius: '6px',
              cursor: 'pointer',
              zIndex: 10,
            }}
          />
        )}
        style={{ width: '101%' }}
      >
        <Header />
        {/* <Header /> */}
        {/* <button
                    style={{position: 'absolute', top: '300px', zIndex: '999'}}
                    onClick={() => {
                        scrollRef.current.scrollToTop(0)
                    }}
                >
                    Clikc
                </button> */}
        <Content>
          <Outlet />
          <div className="mask"></div>
          {/* <img loading="lazy" alt="" className="background-img" src={BgImg} /> */}
        </Content>
        {/* {isActive && <Pooling />} */}
      </ContentWrapper>
      {/* <ContentWrapper></ContentWrapper> */}

      {accountModalVisible && (
        <AccountModal visible={accountModalVisible} onCancel={handleCancel} />
      )}
      {/* <AccountDrawer open={accountModalVisible} onCancel={handleCancel}></AccountDrawer> */}
    </IndexWrapper>
  );
});

Index.displayName = 'IndexPageWrapper';

export default Index;

const IndexWrapper = styled.div`
  height: 100vh;
  /* min-width: 1264px; */
  width: 100%;
  /* min-width: 1200px; */
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.5s;
  background: #0e0b14;

  background-image: ${`url(${BgElementImg})`};
  background-repeat: repeat;
  /* background: linear-gradient(
        270deg,
        rgba(35, 41, 43, 1) 0%,
        rgba(39, 44, 46, 1) 26.56%,
        rgba(29, 27, 24, 1) 78.13%,
        rgba(22, 25, 27, 1) 100%
    ); */

  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    background: linear-gradient(
      to bottom,
      #1b1a1f 0%,
      #1b1a1f 25%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .background-img {
    position: fixed;
    transform: translate(-50%, 0);
    left: 50%;
    top: 0;
    width: 177.78vh;
    height: 100vh;
    min-width: 100vw;
    max-width: 177.78vh;
    z-index: 0 !important;
    /* min-height: calc(100vh - 114px); */
    /* animation: ${() => pendingArrows()} 5s infinite linear; */
  }
`;

const ContentWrapper = styled(Scrollbars)`
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  padding-top: 114px;
`;
const Content = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 60px;

  > * {
    z-index: 2;
  }
`;

const pendingArrows = () => keyframes`
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
`;
