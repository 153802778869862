import { Interface } from '@ethersproject/abi';
import { BigNumberish } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useEffect, useState } from 'react';
import useAlert from '@/hooks/useAlert';
import ERC20_ABI from '@/web3/abi/erc20.json';
import GStakeAbi from '@/web3/abi/gstake.json';
import { getCurrentChainId } from '@/web3/constants';
import { useActiveWeb3React } from '@/web3/WalletProvider';

import { GRT_SALT, GSTAKE_CONTRACT, GstakeTokenList } from '../useCjcNftAddr';

const chainId = getCurrentChainId();

export function useGstakeContract() {
  const { web3Provider, account } = useActiveWeb3React();
  const [gstakeContract, setGstakeContract] = useState<Contract | null>(null);
  const { setAlert } = useAlert();

  useEffect(() => {
    if (!web3Provider) {
      return;
    }
    const _gstakeContract = new Contract(
      GSTAKE_CONTRACT,
      GStakeAbi,
      web3Provider.getSigner(),
    );
    setGstakeContract(_gstakeContract);
  }, [web3Provider]);

  const getNonce = async (tokenContract: Contract, account: string) => {
    try {
      const nonces = await tokenContract.nonces(account);
      return nonces.toString();
    } catch (e) {
      console.log('getNonce err:', e);
    }
  };

  const parseSignature = (signature: string) => {
    const parsedSignature = signature.substring(2);
    const r = parsedSignature.substring(0, 64);
    const s = parsedSignature.substring(64, 128);
    const v = parsedSignature.substring(128, 130);
    return {
      r: '0x' + r,
      s: '0x' + s,
      v: parseInt(v, 16),
    };
  };

  const approveGrt = async (approveAmount: number | BigNumberish) => {
    if (!gstakeContract) {
      return;
    }

    try {
      const deadline = Math.floor(new Date().getTime() / 1000) + 600;
      const tokenContract = new Contract(
        GstakeTokenList.GRT.tokenAddress,
        ERC20_ABI,
        web3Provider.getSigner(),
      );
      const nonce = await getNonce(tokenContract, account as string);
      const domain = {
        name: 'Graph Token',
        version: '0',
        chainId: chainId,
        verifyingContract: GstakeTokenList.GRT.tokenAddress,
        salt: GRT_SALT,
      };
      // The named list of all type definitions
      const types = {
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      };
      // The data to sign
      const value = {
        owner: account,
        spender: gstakeContract.address,
        value: approveAmount,
        nonce,
        deadline,
      };
      const signature = await web3Provider
        .getSigner()
        ._signTypedData(domain, types, value);
      const parsedSignature = parseSignature(signature);
      console.log(parsedSignature, 'check parsedSignature');

      return {
        value: approveAmount,
        deadline,
        v: parsedSignature.v,
        r: parsedSignature.r,
        s: parsedSignature.s,
      };

      // const tx = await gstakeContract.populateTransaction.permitGRT({
      //   value: approveAmount,
      //   deadline,
      //   v: parsedSignature.v,
      //   r: parsedSignature.r,
      //   s: parsedSignature.s,
      // });

      // console.log(tx, 'check parsedSignature 2');
      // return tx;
    } catch (e: any) {
      console.log('approveGrt err:', e);
      throw e;
    }
  };

  const deposit = async (amount: number | string | BigNumberish, needAuth?: boolean) => {
    if (!gstakeContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }

    try {
      let tx;
      // const estimateGas = await gstakeContract.estimateGas.deposit(amount, account);
      // let approveTx = null;

      // if (needAuth) {
      //   approveTx = await approveGrt(amount);
      // }

      if (needAuth) {
        const approveParams = await approveGrt(amount);

        const estimateGas = await gstakeContract.estimateGas.depositWithPermit(
          amount,
          account,
          approveParams,
        );

        const gasLimit = estimateGas.toString();
        tx = await gstakeContract.depositWithPermit(amount, account, approveParams, {
          gasLimit,
        });
      } else {
        tx = await gstakeContract.depositWithUpdate(amount, account);
      }

      // const updatedGas = parseInt(Number(estimateGas) * 1.3 + '');
      // const depositTx = await gstakeContract.populateTransaction.deposit(amount, account);
      // const params = approveTx ? [approveTx.data, depositTx.data] : [depositTx.data];

      // console.log(params, 'check params');

      // const estimateGas = await gstakeContract.estimateGas.multicall(params);
      // const gasLimit = +estimateGas.toString();
      // const tx = await gstakeContract.multicall(params, {
      //   gasLimit,
      // });

      return tx;
    } catch (error: any) {
      throw error;
    }
  };

  const withdraw = async (amount: number | string | BigNumberish) => {
    if (!gstakeContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }

    try {
      // const estimateGas = await gstakeContract.estimateGas.requestWithdrawal(amount, 0);
      // const gasLimit = +estimateGas.toString();
      const tx = await gstakeContract.withdraw(account, amount);

      return tx;
    } catch (error) {
      console.log('withdraw err:', error);
      throw error;
    }
  };

  const claim = async (ids: string[]) => {
    if (!gstakeContract) {
      setAlert({ type: 'warning', message: 'Service not prepared' });
      return;
    }

    console.log(ids, 'claim');

    try {
      // const updatedGas = parseInt(Number(estimateGas) * 1.3 + '');
      const estimateGas = await gstakeContract.estimateGas.claimWithdrawals(ids);
      const gasLimit = estimateGas.toString();
      const tx = await gstakeContract.claimWithdrawals(ids, {
        gasLimit,
      });

      return tx;
    } catch (error) {
      console.log('claim err:', error);
      throw error;
    }
  };

  const getClaimTokenIdFromLogs = (logs: any[]) => {
    const targetLog = logs?.[3];

    if (!targetLog || !targetLog?.topics.length) {
      return null;
    }

    const iFace = new Interface(GStakeAbi);

    const result = iFace.parseLog(targetLog);

    console.log(result, 'check log parsed');
    if (!result?.args) {
      return null;
    }

    const tokenId = result.args['tokenId'];
    const grtAmount = result.args['grt'];
    const wstGrtAmount = result.args['wstGRT'];

    console.log(
      {
        id: Number(tokenId),
        status: '1',
        wstGRT: formatUnits(wstGrtAmount, 18),
        grtAmount: formatUnits(grtAmount, 18),
      },
      'check log 1',
    );
    if (!tokenId || !grtAmount) {
      return null;
    }

    return {
      id: Number(tokenId),
      status: '1',
      wstGRT: formatUnits(wstGrtAmount, 18),
      amountOfGRT: formatUnits(grtAmount, 18),
    };
  };

  return {
    deposit,
    withdraw,
    claim,
    gstakeContract,
    getClaimTokenIdFromLogs,
  };
}
