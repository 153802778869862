import styled from '@emotion/styled';
import clsx from 'clsx';
import React, { memo, useState } from 'react';

import { StyledPanel } from './MainPanel';

export const SingleFaq = memo(
  ({
    title,
    content,
    defaultOpen,
  }: {
    title: string;
    content: string | JSX.Element;
    defaultOpen?: boolean;
  }) => {
    const [open, setOpen] = useState(defaultOpen);

    const handleClick = () => {
      setOpen((v) => !v);
    };

    return (
      <FaqWrap>
        {/* eslint-disable-next-line*/}
        <div onClick={handleClick} className={clsx('title', open && 'isOpen')}>
          {title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {open && <div className="content">{content}</div>}
      </FaqWrap>
    );
  },
);

SingleFaq.displayName = 'SingleFaq';

const QUESTIONS_LIST = [
  {
    title: 'How does Gstake for The Graph work?',
    content:
      'The Gstake protocol aggregates user deposits of GRT and stakes them on The Graph network. The WstGRT, minted by users, can be viewed as shares in Gstake, which entitle them to staking rewards generated from The Graph. When users redeem GRT using WstGRT, they initiate the redemption process on the network by burning WstGRT. This process enables them to withdraw both the principal and the accumulated staking rewards.',
  },
  {
    title: 'What is Gstake staking APR for The Graph?',
    content: 'Gstake staking APR  = The Graph Protocol APR * (1 - Protocol fee)',
  },
  {
    title: 'What is reward fee? What is this used for?',
    content:
      'The protocol imposes a 10% fee on staking rewards. This fee is allocated for protocol upgrades, maintenance, and a risk assurance fund. As a result, users will receive 90% of the staking rewards distributed by the network.',
  },
  {
    title: 'What is stake tax?',
    content:
      'Gstake stakes GRT to The Graph protocol through a set of delegators. Each time a delegator stakes GRT to The Graph protocol, The Graph automatically destroys 0.5% of the total amount staked.',
  },
  // {
  //   title: 'Is it safe to work with Gstake?',
  //   content: (
  //     <>
  //       In order to work safe, Gstake fits the next points:
  //       <br />- Open-sourcing & continuous review of all code.
  //       <br />- The protocol enforces a deposit cap, moderating the rate of growth and
  //       mitigating risk by imposing constraints on the expansion of the protocol.
  //       <br />- A risk reserve is established as a contingency measure.
  //       <br />- Rigorous security audits are conducted.
  //     </>
  //   ),
  // },
  // {
  //   title: 'What are the risks of staking with Gstake?',
  //   content: (
  //     <>
  //       There exist a number of potential risks when staking using liquid staking
  //       protocols.
  //       <br /> - Smart contract security
  //       <br />
  //       There is an inherent risk that Gstake could contain a smart contract vulnerability
  //       or bug. The Gstake code is open-sourced, audited and covered by an extensive bug
  //       bounty program to minimise this risk. To mitigate smart contract risks, all of the
  //       core Gstake contracts are audited.
  //       <br /> - WstGRT price risk
  //       <br />
  //       Users risk an exchange price of WstGRT which is lower than inherent value due to
  //       withdrawal restrictions on Gstake, making arbitrage and risk-free market-making
  //       impossible. The Gstake is driven to mitigate the above risks and eliminate them
  //       entirely to the extent possible. Despite this, they may still exist and, as such,
  //       it is our duty to communicate them.
  //     </>
  //   ),
  // },
  // {
  //   title: 'What is Gstake staking APR for TheGraph?',
  //   content: 'Gstake staking APR  = TheGraph Protocol APR * (1 - Protocol fee)',
  // },
  // {
  //   title: 'What fee is applied by Gstake? What is this used for?',
  //   content:
  //     'The protocol levies a 10% fee on staking rewards. This fee is allocated towards protocol upgrades, maintenance, and a risk assurance fund. Consequently, users will receive 90% of the staking rewards returned by the network.',
  // },
];

const Questions = memo((props: any) => {
  return (
    <QuestionsWrapper>
      <div className="title">FAQ</div>

      {QUESTIONS_LIST.map((item, index) => (
        <SingleFaq
          defaultOpen={index === 0}
          key={item.title + index}
          title={item.title}
          content={item.content}
        />
      ))}
    </QuestionsWrapper>
  );
});

Questions.displayName = 'Questions';

export const QuestionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 48px;

  > .title {
    width: 500px;
    text-align: left;
    color: #fff;
    font-family: 'Alibaba';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 18px;
  }
`;

export const FaqWrap = styled(StyledPanel)`
  margin-bottom: 18px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-family: 'Alibaba';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

    &.isOpen {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  .content {
    color: #999;
    font-family: 'Alibaba';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 26px;
  }
`;

export default Questions;
